.col_icon {
  display: flex;

  align-items: center;

  gap: 10px;
}

.no_data_show {
  height: 60vh;

  width: 100vw;

  display: grid;

  place-items: center;
}

.no_data_show > div {
  display: flex;

  flex-direction: column;

  gap: 10px;

  align-items: center;
}
