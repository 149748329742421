#job-list-excess-control-main {
  /* border: 1px solid red; */
  background-color: #ebebeb;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

#joblist-search-main {
  display: flex;
  gap: 10px;
}
#joblist-search-main > div:nth-child(1) {
  width: 30%;
}
#joblist-search-main > div:nth-child(2) {
  width: 20%;
}
#joblist-search-main > div:nth-child(3) {
  width: 20%;
}
#joblist-search-main > div:nth-child(4) {
  width: 20%;
}
#joblist-search-main > div:nth-child(5) {
  width: 10%;
}

#joblist-action-main {
  /* border: 1px solid red; */
  padding: 10px;
  display: flex;
}
#joblist-action-main > div {
  /* border: 1px solid red; */
}

#joblist-action-main > div:nth-child(1) {
  width: 60%;
}
#joblist-action-main > div:nth-child(2) {
  width: 25%;
  font-size: 10px !important;
}
#joblist-action-main > div:nth-child(3) {
  width: 15%;
}

.joblist-required-checkbox {
  background: #1890ff;
  /* padding: 3px !important;  */
  border-radius: 5px;
  color: white !important;
  padding: 3px !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.joblist-saveforlater-btn {
  padding: 3px !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.joblist-save-btn {
  padding: 5px !important;
  font-size: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: auto;
}

.joblist-share-btn {
  padding: 4px !important;
  padding-top: 1px !important;
  border-radius: 50% !important;
  margin-left: 4px;
  height: 26px !important;
  /* padding-bottom: 10px !important; */
  font-size: 15px !important;
}
.joblist-share-btn > span {
  margin-top: -5px !important;
}

#joblist-table-jobtitle-cont {
  width: 250px;
}
#joblist-table-jobtitle-cont > div:nth-child(1) {
  font-size: 18px;
}
#joblist-table-jobtitle-cont > div:nth-child(1):hover {
  text-decoration: underline;
  font-size: 18px;
}

.joblist-table-jobtitle-alert1-cont {
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
  background: #51a5f1;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  color: white;
}
.joblist-table-jobtitle-alert2-cont {
  height: 20px;
  border-radius: 5px;
  background: #ddf2f2;
  font-size: 11px;
  color: #095b5b;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
}

#job-close-model-image-cont {
  /* border: 1px solid red; */
  height: 100px;
  width: 100px;
  margin: auto;
  margin-bottom: 20px;
}

#job-close-model-image-cont > img {
  width: 100%;
  height: 100%;
}

#job-close-model-btton-cont {
  padding: 10px;
  justify-items: space-around;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
