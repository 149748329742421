.card_of_job_filter {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
}

.company_name_list {
  padding-left: 10px;
  color: black !important;
  text-decoration: none !important;
}

.screened-applicant-candidate-list {
  width: 70%;
  margin: auto;
  text-align: center;
}

.referal_partner_table {
  display: flex;
  gap: 10px;
}
.referal_partner_table > div:nth-child(1) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.referal_partner_table > div:nth-child(2) {
  text-align: center;
  padding-top: 10px;
}
.referal_partner_table > div:nth-child(1) > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
