#job-attachmements-cont {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 50px;
}

#job-application-deadline {
  margin-top: 15px;
  height: 38px;
  /* border: 1px solid red; */
}

#job-desc-file-uploader {
  width: 100% !important;
}

.lkjDVC {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 80px !important;
  border: dashed 1px #b0b0b0 !important;
  padding: 8px 16px 8px 8px;
  border-radius: 1px !important;
  cursor: pointer;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
