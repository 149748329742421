.job_header_partner {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  padding: 20px;
  gap: 10px;
  /* justify-content: center; */
}

.job_header > h3 {
  font-weight: bolder;
}
