.forget_password_container {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.forget_password_elem_container {
    width: 30vw;
    /* height: 88vh; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: 30px;

}

@media (max-width:800px) {
    .forget_password_elem_container {
        width: 60vw;
    }
}

@media (max-width:1200px) {
    .forget_password_elem_container {
        width: 60vw;
    }
}

.forget_password_image {
    width: 180px;
}