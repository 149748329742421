.email_header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.email_header > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.thumb_holder {
  display: flex;
  align-items: center;
  gap: 10px;
}

.thumb_holder > i {
  color: rgb(10, 187, 135);
}

.thumb_holder > span {
  font-size: 16px;
  font-weight: 800;
}

.email_header_container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  /* border-radius: 10px; */
  border-bottom: 1px solid #dbdbdb;

  padding: 10px;
  align-items: center;
}

.email_container_header {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
  align-items: center;
}

.email_container_header > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal_overide > .modal-content {
  background-color: #f6f6f6 !important;
}

.email_container {
  margin-top: 20px;
  /* border: 1px solid #bababa; */
  background-color: white;
  /* border-bottom: 1px solid #dbdbdb; */

  padding: 10px;
  /* background-color: #f6f6f6; */
  /* box-shadow: 10px 10px 20px -1px rgba(0, 0, 0, 0.1), -10px -10px 20px -1px rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  min-height: 60vh;
}

.email_header_content {
  font-size: 15px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.email_sender_details {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-between;
}

.email_header_details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.email_sender_details > div > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.first_email_header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sender_name {
  font-weight: 600;
}

.email_content {
  padding: 20px 10px;
}

.email_data {
  background-color: white;
  /* border-radius: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.report_sidebar {
  width: 100%;
  float: left;
  padding: 20px;
  min-height: 80vh;
  border-radius: 5px;
}

.candidate_card {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  margin-bottom: 20px;
  /* box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1); */
  margin-top: 10px;
}

.candidate_card > h6 {
  color: gray;
}

.candidate_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.active_data {
  color: green !important;
}
