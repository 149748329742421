.login_parent {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.login_img_section {
  flex: 0.3;
  display: flex;
  background-color: var(--sidebar--color);
  flex-direction: column;
  gap: 100px;
  align-items: center;
  padding: 25px;
  justify-content: center;
}

.card_image {
  flex: 0.7;
  display: grid !important;
  place-items: center !important;
  margin: 0px !important;
}

.main_content_login {
  width: 60%;
  position: relative;
  margin-bottom: 70px;
  /* height: 390px; */
}


.head_login {
  margin-bottom: 100px;
  text-align: center;
  font-size: 26px;
  letter-spacing: 10px;
  font-weight: bolder !important;
}

.welcome_text {
  margin-bottom: 40px;
  text-align: center;
  font-size: 26px;
  color: gray;
  letter-spacing: 1px;
  font-weight: bolder !important;
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
  top: 10px;
  right: 10px;
}

.login_inputs {
  border-radius: 10px !important;
  /* border: 1px solid rgb(143, 143, 143) !important; */
}

.login_form>* {
  margin-bottom: 20px;
}


@media(max-width:800px) {
  .login_parent>img {
    display: none;
  }

  .card_image {
    flex: 1;
  }
}

@media(max-width:1000px) {
  .main_content_login {
    width: 90% !important;
  }
}