.formLable {
  padding: 14px !important;
  background-color: #f5f7f9 !important;
  border: 0px !important;
  border-radius: 8px !important;
}

.loader_container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.place_items_center {
  place-items: center;
}