.select_for_job_type {
  border: 1px solid var(--theme-color--light);
  background-color: transparent;
  color: var(--theme-color--light);
  width: 90%;
  border-radius: 5px;
  padding: 10px;
}

.select_for_job_type:hover,
.selected_for_job_type {
  background-color: var(--theme-color--light);
  color: white;
}

.job_types_selection {
  border: 1px solid #919191;
  border-radius: 3px;
  width: 90%;
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  display: grid;
  min-height: 300px;
  /* width: 100%; */
  place-items: center;

  user-select: none;
}

.job_types_selection:hover {
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.07);
  border: 0.5px solid var(--theme-color--light);
}

.selected_job_type {
  position: relative;
  transform: translateY(-3px);
  color: var(--theme-color--light) !important;
  border: 0.5px solid var(--theme-color--light);
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.07);
}

.info_job_type {
  color: #3483eb;
  font-size: 11px;
  background-color: transparent;
  outline: none;
  border: none;
}