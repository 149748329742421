.two_btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.question_list_item {
  background-color: white;
  /* box-shadow: 0px 10px 20px -1px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  min-height: 80px;
  display: grid;
  margin-bottom: 10px !important;
  transition: 0.3s ease-in-out;
}

.question_list_item:hover {
  transform: scale(1.02);
  box-shadow: 10px 10px 20px rgba(0, 0, 0.4);
  transform: translateY(-2px);
}

.delete_icon {
  background-color: hsla(0, 86%, 69%, 0.18) !important;
}

.edit_icon {
  background-color: rgba(52, 195, 143, 0.18) !important;
}

.icon_box {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background-color: white;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}

.icon_box:hover {
  transform: translateY(-4px);
}
