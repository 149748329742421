.nav__container {
  width: 100vw;
}

.dashboard_wrapper {
  display: flex;
  /* flex-direction: ; */
  margin-top: 120px;
  /* flex-wrap: wrap; */
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.tab_bar {
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  gap: 20px;
  justify-content: space-between;
}

.tab_bar::-webkit-scrollbar {
  display: none;
}


.clicked_tab {
  background-color: #021a39;
  color: white;
  /* background-color: var(--dark--color--sidebar); */
}

.revert {
  /* transform: rotate(0deg); */
  border: 2px solid rgb(193, 85, 85);
  border-radius: 3px;
}

.img {
  height: 70px;
}

.header_job_h5 {
  text-transform: uppercase;
  /* font-weight: bolder; */
}

.job_info_card {
  display: flex;
  color: gray;
  font-size: 10px;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
}

.job_info_card>button {
  border: none;
  background-color: transparent;
  outline: none;
  margin-bottom: 0.9rem;
  margin-left: auto;
}

.link_btn_preview {
  color: #50a5f1 !important;
}

.link_btn_preview:hover {
  border: none !important;
}

@media (max-width: 9n80px) {
  .global_card {
    min-width: 100% !important;
  }
}