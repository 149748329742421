.assigned_jobs {
  display: flex;
  min-height: 500px;
  margin-top: 20px;
  justify-content: space-around;
  /* height: 80vh; */
}

.anticon-rocket {
  font-size: 70px !important;
  font-weight: bold;
  transform: rotate(50deg);
}

.assigned_container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.job_cont {
  /* padding-top: 10px; */
  margin-bottom: 20px;
}

.reward_tab {
  display: flex;
  margin-top: 8px;
  align-items: center;
  justify-content: flex-start;
}

.reward_tab > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.job_info_tab {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .job_cont {
    margin-bottom: 10px;
  }
}
