@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap");

.job_card {
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
  padding: 20px;
  padding-bottom: 30px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: 10px;
  /* min-height: 100%; */
}

.job-edit-icon {
  background-color: var(--theme-color--light);
  padding: 10px;
  position: absolute;
  color: white;
  border-radius: 50%;
  top: -10px;
  right: -10px;
}

.image-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_job_list {
  /* margin-top: 20px; */
  /* position: absolute; */
  /* bottom: 10px; */
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  /* right: 20px; */
  /* left: 20px; */
}

.profile_alternative {
  font-weight: bolder;
  display: grid;
  place-items: center;
  width: 50px;
  /* background-color: rgb(255, 216, 124); */
  /* color: rgb(200, 140, 0); */
  text-transform: capitalize;
  height: 50px;
  border-radius: 50%;
}

.header_profile_alternative {
  font-weight: bolder;
  display: grid;
  place-items: center;
  width: 30px;
  /* background-color: rgb(255, 216, 124); */
  /* color: rgb(200, 140, 0); */
  text-transform: capitalize;
  height: 30px;
  border-radius: 50%;
}

.job_card:hover {
  transform: translateY(-3px);
  box-shadow: var(--global-shadow);
}

.job_details {
  display: flex;
  flex-direction: column;
}

.job_details_header {
  border-radius: 10px;

  font-weight: bolder;
  padding: 3px 7px;
  border: 1px solid;
  display: grid;
  /* margin-bottom: 8px; */
  font-size: 10px;
  place-items: center;
}

.header_3 {
  border-color: transparent;
  color: #057b7d;

  font-weight: bolder;
  font-size: 10px;
}

.header_1 {
  border-color: rgb(221, 239, 255);
  color: var(--theme-color--light);
}

.header_2 {
  color: #c8382d;
  border-color: #f4d7d5;
}

.header_4 {
  border-color: #dbdbde;
  color: #494b59;
}

.header_5 {
  border-color: #dbddeb;
  color: #49569c;
}

.job-title {

  font-weight: bolder;
  color: var(--theme-color--light) !important;
}

.job-title>a {
  color: var(--theme-color--light) !important;
}

.job_details_desc {
  color: gray;

  font-weight: bolder;
  font-size: 10px;
}

.key_skills {
  background-color: aliceblue;
  border-radius: 4px;
  color: var(--theme-color--light);
  padding: 5px;
}

.location {
  color: gray;
  font-size: 10px;
}

.job_description {
  max-height: 100px;
  height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(164, 164, 164);
  word-wrap: break-word;
}

.currency-text,
.currency-time {

  font-weight: bolder;
}

.delete_btn_job {
  color: red;
  display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
}

.approve_btn_job {
  color: green;
  display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
}