.popup_head {
  background-color: rgba(93, 120, 255, 0.1);
  padding: 8px 0px;
  width: 100%;
  color: var(--background-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
}

.popup_head > div {
  font-size: 30px;
  padding: 6px;
  display: grid;
  place-items: center;
  color: white;
  position: relative;
  border-radius: 50%;
  background-color: var(--background-color);
}

.popup_head > div > i {
  font-size: 18px !important;
}

.popup_head > div:nth-child(1)::after,
.popup_head > div:nth-child(2)::after {
  top: 49%;
  right: -100px;
  position: absolute;
  content: "";
  height: 3px;
  width: 100px;
  background-color: var(--background-color);
}

.skill_box {
  padding: 10px !important;
  border: 1px solid #bababa;
}

.labels_img {
  margin: 0px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.job_detals_model_main {
  margin-left: -22px !important;
  margin-bottom: -15px !important;
  box-shadow: rgba(185, 183, 183, 0.15) 6px -7px 0px 7px;
  /* border-radius: 5px; */
}

.job_detals_model_left_cont {
  border-right: 1px solid rgb(208, 204, 204);
}

.job_detals_model_right_cont {
  padding: 25px;
}

.job_detals_model_heder_head {
  min-height: 100px;
  border-bottom: 1px solid rgb(208, 204, 204);
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.job_detals_model_heder_head > div:nth-child(1) {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(208, 204, 204);
  border-radius: 3px;
  margin-top: 5px;
}

.job_detals_model_heder_head > div:nth-child(2) {
  width: 75%;
  height: 100%;
}

.job_detals_model_heder_head > div:nth-child(3) {
  width: 50px;
  height: 100%;
  margin-top: 0px;
}

.job_detals_model_heder_head > div:nth-child(3) > Button {
  border-radius: 50%;
  font-size: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.job_details_img {
  width: 100%;
  height: 100%;
}

.job_detals_model_bottom {
  min-height: 80px;
  border-top: 1px solid rgb(208, 204, 204);
  border-bottom: 1px solid rgb(208, 204, 204);
  margin-bottom: 10px;
  justify-content: left;
  align-items: left;
  padding-top: 10px;
}

.job_details_contents {
  padding: 20px;
}

.jobs_name_for_model {
  padding: 2px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.jobs_name_for_model > p {
  background: rgb(94, 210, 94);
  padding: 5px;
  border-radius: 14px;
  color: white;
}

.about_you_on_jobs_heading {
  font-weight: bolder;
}

.job_detals_model_right_green_box {
  min-height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(212, 230, 248);
  color: var(--theme-color--light);
  border-radius: 10px;
  margin: auto;
}

.job_detals_model_right_green_box > h5 {
  font-weight: bold;
}

.job_detals_model_right_green_box > button {
  width: 150px;
  color: white;
  background-color: var(--theme-color--light);
  text-align: center;
  font-weight: bold;
  margin: auto;
}

.job_details_loacations_main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.job_details_loacations {
  border: 1px solid rgb(208, 204, 204);
  padding: 6px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(235, 234, 234);
}

.job_details_view_more {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
}

.job_details_view_more > Button {
  margin: auto;
  width: 140px;
  font-weight: bold;
  padding: 10px;
}

.details_of_right_side {
  font-weight: bold;
  font-size: 15px;
}
