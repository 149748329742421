#job-location-selection-cont {
  /* border: 1px solid rgb(163, 217, 136); */
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  width: 70%;
  margin-top: 10px;
  /* margin: auto; */
}

#job-location-boxes:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#job-location-boxes {
  border: 1px solid blue;
}

/* Package Css */
.css-1ms1y94-JoyRadioGroup-root .JoyRadio-radio > svg {
  z-index: 10 !important;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--joy-palette-common-white);
  border-radius: 50%;
  background: white !important;
}
