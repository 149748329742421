.job-edit-submit-btn {
  border: none;
  outline: none;
  background: white;
  border: 1px solid var(--theme-color--light);
  color: var(--theme-color--light);
  border-radius: 10px;
  width: 50%;
  font-size: 15px !important;
  padding: 10px !important;
  margin: 0% 25% 0% 25% !important;
}

.job-edit-submit-btn:hover {
  color: white;
  background-color: var(--theme-color--light);
}
