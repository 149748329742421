#job-pre-header-cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#job-pre-header-cont > div:nth-child(3) {
  justify-content: flex-end;
  display: flex;
}

#primium-right {
  height: 30px;
  width: 35px;
  background: #3d82f5;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

#premium {
  /*   	border: 1px solid red; */
  height: 30px;
  width: 90px;
  background: #3d82f5;
  text-align: center;
  padding-top: 7px;
  border-left: 0.1px solid white;
}

#premium > h6 {
  color: white !important;
}

#primeum-right {
  width: 30px;
  height: 30px;
  /*   border: 1px solid red; */
}

#primeum-right > div:nth-child(1) {
  border-top: 0px solid transparent;
  border-bottom: 15px solid transparent;
  margin-left: -0.5px;
  border-left: 20px solid #3d82f5;
}

#primeum-right > div:nth-child(2) {
  /*   border: 1px solid green;
    height: 20px;
    border-top-right-radius : 200px; */
  border-top: 15px solid transparent;
  border-bottom: 0px solid transparent;
  margin-left: -0.5px;

  border-left: 20px solid #3d82f5;
}

#primium-icon {
  color: rgb(255, 218, 11) !important;
  font-size: 15px;
  padding: 7px;
}

#hiring-day-cont {
  /* border: 1px solid red; */
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#hiring-day-cont > div:nth-child(1) {
  /* border: 1px solid red; */

  padding: 10px;
}

#hiring-day-cont > div:nth-child(2) > div {
  display: flex;
  margin-top: 10px;
}

#hiring-day-cont > div:nth-child(2) > div > div:nth-child(1) {
  color: #3d82f5;
  padding-left: 10px;
  padding-right: 10px;
}

#hiring-day-cont > div:nth-child(2) > div > div:nth-child(2) > p > span {
  font-weight: bolder;
  font-size: 14px;
}
