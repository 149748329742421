.sidebar {
  background-color: var(--sidebar--color);
  width: 70px;
  z-index: 99999;
  top: 0px;
  left: 0px;
  display: flex;
  /* overflow-x: visible; */
  flex-direction: column;
  gap: 10px;
  /* overflow-y: scroll; */
  align-items: center;
  position: fixed;
  min-height: 100vh;
}

.overXhide{
  overflow-x: hidden;
}


.left_main_content {
  /* position: fixed; */
  top: 0px;
  background-color: white !important;
  /* overflow: scroll; */
  right: 0px;
  bottom: 0px;
  /* min-height: 100vh; */
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.text_sidebar {
  font-size: 13px;
  white-space: nowrap;
  width: 100%;
  color: white !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p_ab {
  position: absolute;
}

.p_fix {
  position: fixed;
}

/* 0f0d0f */
/* #021a39 */
.width_f {
  width: 208px !important;
  box-shadow: 15px 0px 20px 1px rgba(0, 0, 0, 0.1);
}

.work_svg {
  background-image: url("../../images/icons/workmode.svg");
}

.skill_svg {
  background-image: url("../../images/icons/skills.svg");
}

.fn_svg {
  background-image: url("../../images/icons/functions.svg");
}

.buisness_svg {
  background-image: url("../../images/icons/buisness.svg");
}

.size_color {
  width: 20px;
  height: 20px;
  background-size: contain;
  color: white;
}

.grad_svg {
  background-image: url("../../images/icons/icon_white.svg");
}

.div_big {
  position: absolute;
  top: 60px;
  right: -20px;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 18px;
  z-index: 99;
  background-color: rgb(233, 233, 233);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@keyframes mover {
  0% {
    right: -18px;
  }

  100% {
    right: -30px;
    padding: 4px;
  }
}

.div_big>i {
  color: black;
  margin-top: 0px;
  height: 21px;
}

.list_icons:last-child {
  margin-bottom: 80px;
}

.sidebar_user_details_container {
  background-color: var(--sidebar--color);
  padding: 5px;
  margin: 7px 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.logo_sidebar {
  position: fixed;
  padding-top: 15px;
  /* padding-left: 15px; */
  padding-bottom: 15px;
  z-index: 1;
}

.sidebar_collapse_handler_container {
  position: relative;
  width: 100%;
}

.sidebar_collapse_handler {
  /* position: absolute; */
  /* top: 10px; */
  border-radius: 10%;

  display: grid;
  padding-top: 3px;
  color: white;
  margin-right: 5px;
  background-color: var(--sidebar--color--opacited);
  place-items: center;
  cursor: pointer;
  padding: 6px;
  font-size: 18px;
  z-index: 99;

}

.sidebar_user_details {

  /* background-color: var(--sidebar--color); */
  position: fixed;
  bottom: -16px;
  left: 0px;
  /* right: 0px; */
  padding: 10px 5px;
  color: white;

}

.w_img_f {
  width: 208px;
  display: grid;
  place-content: center;
  background-color: var(--sidebar--color);
}

.w_img_m {
  width: 70px;
  background-color: var(--sidebar--color);
}

.logo_img {
  width: 20px;
  height: 20px;
}

.logo_full {
  width: 100px;
  margin-left: -20px;
  object-fit: contain;
}

.w_full {
  width: 90%;
}

.sidebar_list {
  overflow-y: scroll;
  height: calc(100vh - 30px);
  margin-top: 80px;
  list-style: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rot_full {
  transform: rotate(90deg);
}
.rot_full2 {
  transform:rotate(180deg);
}

.sidebar_list::-webkit-scrollbar {
  display: none;
}

.list_icons,
.arrow_icon {
  display: flex;
  color: white;
  text-decoration: none;
  text-transform: capitalize;
  align-items: center;
  gap: 10px;
}

.list_icons>i {
  color: white;
  font-size: 20px;
  /* font-weight: 100; */
}

.list_icons::after {
  top: 50%;
  left: -14px;
  content: "";
  height: 0%;
  /* width: 4px; */
  overflow: auto;
  position: absolute;
  transition: 0.2s ease-in-out;
  /* height: 0%; */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.list_icons::before,
.arrow_icon::before {
  content: "";
  position: absolute;
  top: 0px;
  right: -25px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  color: white;
  /* height: 40px; */
  width: 0;
  background-color: #021a39;
}

.drop_cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  /* z-index: -1 !important; */
  top: -20px;
  height: 0px;
}

.up_s {
  overflow: hidden;
}

.down_s {
  height: auto !important;
  top: 10px;
}

/* .list_icons:hover::before,
.arrow_icon:hover::before {
  content: attr(data-label);
  z-index: 999999;
  right: -170px;
  width: 150px;
  padding: 10px;
  text-align: center;
} */

.arrow_icon>i {
  color: white;
  font-size: 16px !important;
}

.list_icons:hover:after,
.list_click:after {
  top: 0px;
  height: 95%;
  background-color: rgb(193, 193, 193);
}

.item_exp {
  padding-left: 30px !important;
}

.sidebar_list>li>span,
.list_icons>li>span {
  color: white;
  text-transform: capitalize;
}

.sidebar_list>li,
.list_icons {
  padding: 10px;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
}

.sidebar_list>li:hover,
.list_icons:hover {
  background-color: rgba(193, 193, 193, 0.3);
}

.list_click {
  background-color: rgba(193, 193, 193, 0.3);
}

#sidebar_dropdowns:last-child {
  margin-bottom: 10px;
}