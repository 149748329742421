.job_hours {
  border: 1px solid #919191;
  border-radius: 3px;
  width: auto;
  cursor: pointer;
  height: auto;
  padding: 10px;
  height: 100px;
  background-color: transparent;
  display: grid;
  width: 70%;
  place-items: center;

  user-select: none;
}

.job_hours:hover {
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--theme-color--light);
}

.selected_hours::after {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--theme-color--light);
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2713";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  color: white;
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 1rem;
}

.selected_hours {
  position: relative;
  transform: translateY(-3px);
  border: 1px solid var(--theme-color--light);
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.07);
}

.info_job_type {
  color: #3483eb;
  font-size: 11px;
  background-color: transparent;
  outline: none;
  border: none;
}

.work_modes_selection {
  border: 1px solid #919191;
  border-radius: 3px;
  width: auto;
  /* padding: 10px; */
  cursor: pointer;
  padding: 10px;
  height: 80px;
  min-height: 200px;
  background-color: transparent;
  display: grid;
  width: 90%;
  object-fit: contain;
  place-items: center;

  user-select: none;
}

.work_modes_selection>label {
  font-weight: bolder;
}

.work_modes_selection>img {
  width: 200px !important;
}

.work_modes_selection:hover {
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--theme-color--light);
}

.selected_work_mode>label {
  color: var(--theme-color--light);
}

.selected_work_mode::after {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--theme-color--light);
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2713";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  color: white;
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 1rem;
}

.selected_work_mode {
  position: relative;
  border: 1px solid var(--theme-color--light);
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.1);
}