.perks_selection {
  border: 0.5px solid #919191;
  border-radius: 8px;
  width: auto;
  /* padding: 10px; */
  cursor: pointer;
  padding: 10px;
  height: 100px;
  background-color: transparent;
  display: grid;
  width: 100%;
  place-items: center;
  user-select: none;
}

.perks_selection:hover {
  border: 0.5px solid #008000;
}


.perks_selection>p {
  margin: 0px;
}

.selected_perk {
  position: relative;
  border: 1px solid #008000;
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.1);
}

.selected_perk::after {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #008000;
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2713";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  color: white;
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 1rem;
}

.cluture_image {
  border-radius: 10px;
  display: grid;
  height: 200px;
  place-items: center;
  /* max-width: ; */
  border: 1px solid #919191;
}

.row_sm {
  display: flex;
  width: 80%;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.row_sm>img {
  width: 100px;
  height: 100px;
}

.small_gray {
  color: gray;
  font-size: 10px !important;
}

.flex_center {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}