.loader_icon {
    animation-name: rotate_icon;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: center;
}

@keyframes rotate_icon {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}