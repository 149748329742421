.comment_scrollable_area {
  max-height: 80vh;
  height: 80vh;
  overflow-y: scroll;
}

.comment_time {
  color: gray;
  font-size: 10px;
}

.send_comments {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.recived_comments {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.send_details {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.input_comment {
  position: absolute;
  bottom: -10px;
  background-color: white;

}