.settings_header {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
}

.alert_small {
  width: 200px;
}

.bold_success {
  font-weight: bold;
  margin: 0px;
}

.alert_small_comp {
  margin: 0px !important;
  display: grid;
  place-items: center;
}

.settings_header_first {
  display: flex;
  gap: 10px;
  align-items: center;
}

.settings_header_profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-weight: bold;
  display: grid;
  place-items: center;
  color: var(--background-color);
  background-color: #e1f5fe;
}

.settings_header_profile>img {
  border-radius: 50%;
}

.settings_header_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email_brands {

  padding: 10px 20px;
  position: relative;
  margin: 0px !important;
  /* justify-content: space-between; */
}

.email_brands::after {
  content: "";
  left: 0px;
  position: absolute;
  width: 4px;
  /* border-radius: 10px; */
  transition: 0.5s ease-in-out;
  top: 50%;
  height: 0%;
  background-color: var(--background-color);
}

.email_brand_div:hover::after {
  top: 0px;
  height: 100%;
  background-color: var(--background-color);
}

.brand_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.brand_wrapper>img,
.brand_wrapper>.icon {
  width: 30px;
  object-fit: contain;
  display: grid;
  place-items: center;
  height: 30px;
}

.icon {
  border: 1px solid #3c3c3c;
  border-radius: 4px;
}

.icon>i {
  font-size: 20px;
}

.brand_wrapper>.pofile_banner {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-weight: bold;
  display: grid;
  place-items: center;
  color: var(--background-color);
  background-color: #e1f5fe;
}

.email_wrapper {
  padding: 10px;
}

.setting-job-staff-pages-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.setting-job-staff-pages-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.email_brand_div {
  padding: 6px 20px;
  background-color: white;
  border-bottom: 1px solid #eaeaea !important;
}

.email_brand_div>p {
  margin: 0px !important;
}

.company_grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company_grid>p {
  margin: 0px !important;
}

.calender_grid {
  /* display: grid; */
  /* place-items: center; */
  padding: 40px;
}

/* don't change */
.new_calender_grid {
  display: grid;
  height: 100%;
  place-items: center;
}

.calender_contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calender_contents>i {
  font-size: 40px;
}

.text_area_like {
  padding: 20px;
  border: 1px solid #bababa;
  border-radius: 10px;
}

.edit_del_btn {
  display: flex;
  gap: 10px;
  align-items: center;
}

.no_data_staff {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}