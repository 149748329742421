.integrations_card {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 230px;
}

.integrations_card > div:nth-child(1) {
  width: 98%;
  margin: auto;
  align-content: flex-end;
  align-items: flex-end;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
}

.integrations_card_headers > div:nth-child(1) {
  padding-top: 20px;
}
.integrations_card_headers > div:nth-child(1) > div > h4 {
  font-weight: bolder;
  font-size: 20px;
}
.integrations_card_headers > div:nth-child(1) > div > a {
  font-size: 16px;
  color: rgb(120, 115, 115);
}
.integration_button {
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  align-self: flex-end;
  padding: 5px;
  background-color: transparent;
  outline: none;
  /* width: 70%; */
}

.integration_button:hover {
  color: #2a9cff;
  border: 1px solid #2a9cff;
}
