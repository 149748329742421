.blog_catgory_card_thumbnail {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  object-position: center;
}

.card_categories {
  overflow: hidden;
  position: relative;
  max-height: 250px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.1);
}

.category_card_name {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding: 0px 10px;
  background-color: white;
  left: 0;
}

.del_btn {
  outline: none;
  border: none;
  color: red;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: rgb(255, 157, 157);
}
