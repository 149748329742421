.posts_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small_img {
  width: 40px;
  object-fit: contain;
  height: 40px;
}

.multi_flex {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

/* .long_filter_data:hover {
    height: 100px;
} */

/* .height_0px {
    height: 0px;
}

.height_auto {
    height: auto;
} */
.mail-list a.active_data {
  background-color: black !important;
  color: white !important;
  border-radius: 4px;
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1);
  /* opacity: 0.9; */
}
