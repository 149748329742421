.anticon svg {
  display: inline-block;
  margin-top: -5px !important;
}

#skills-selected-tags {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
  color: white !important;
  border-radius: 20px !important;
  background: #1890ff !important;
}

#skill-main-cont {
  padding-top: 20px;
  margin-top: 10px;
}
.skill-models-header {
  height: 0px !important;
  border-bottom: 0px !important;
}

.skill-model-select-cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 50px !important;
  margin-bottom: 20px !important;
}

.skill-model-select {
  border-radius: 0px !important;
}

.skill-model-btn {
  margin: auto !important;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 3px !important;
}
.skill-models-footer {
  border-top: 0px !important;
}

#basic-multi-select-box > div {
  /* border: 1px solid red; */
  max-height: 150px !important;
  /* position: relative; */
}
#basic-multi-select-box > div > div {
  max-height: 150px !important;
}
