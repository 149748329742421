.settings_header_profile_details_cont {
  margin-top: 10px;
}

.settings_header_profile_details_cont > h4 {
  font-size: 20px;
  padding-left: 5px;
}

.settings_header_profile_details_cont > h4 > span {
  color: #325684;
  font-size: 10px;
}

.settings_header_profile_details_cont > p {
  margin: 5px;
}

.profile_download_btn {
  text-align: right;
}

#profile_background_main_cont {
  width: 550px;
  height: 350px;
  margin: auto;
}

#profile_background_image {
  border-radius: 10px;
  width: 550px;
  height: 350px;
  /* border: 1px solid red; */
  margin: auto;
}

.profile_overlay {
  /* border: 1px solid red; */
  margin-top: -340px;
  padding: 20px;
  /* margin: auto; */
}

.card-overlay {
  border-radius: 10px;
  height: 325px;
}

.candidate-price {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}

.candidate-name {
  font-weight: 600;
  color: #fff;
  font-size: 16px;
}

.candidate-designation {
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.hire-me-btn {
  border: 1px solid #fff !important;
  color: #fff !important;
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
}

.hire-me-btn:hover {
  border: 1px solid #fff;
  color: #99b2d4;
  /* font-size: 15px; */
  font-weight: 600;
  background-color: transparent;
}

.btn-download:hover {
  color: #99b2d4;
  /* font-size: 15px; */
  font-weight: 600;
  background-color: #000;
}

.btn-download {
  color: #fff !important;
  border: 1px solid #fff !important;
  /* font-size: 15px; */
  font-weight: 600;
  background-color: #000;
}

.location-custom-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgb(56 152 226 / 30%);
}

.about-me {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.user-desc {
  color: rgb(58, 58, 58);
  font-size: 14px;
  font-weight: 450;
}

.skills {
  padding: 5px 15px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #000;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.list-date {
  font-size: 13px;
  color: #626262;
}

.list-title {
  color: #1967d2;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.list-position {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.dotted-pointer {
  width: 20px;
  height: 20px;
  border: 3px solid #dbe6f7;
  border-radius: 50%;
  background-color: #1967d2;
  content: "";
  position: relative;
  left: -45px;
  top: 46px;
}

.line {
  width: 20px;
  height: 1px;
  background-color: #1967d2;
  position: relative;
  left: 18px;
  top: 7px;
}

.text-lable {
  font-size: 14px;
  color: #666666;
}

.text-value {
  font-size: 15px;
  color: #17171d;
  word-break: break-word;
  font-weight: 500;
}

.icon-color {
  color: #1967d2;
  font-size: 18px;
}

.icon-hover {
  /* background-color: #dadada; */
  padding-top: 14px;
  padding-left: 14px;
  width: 40px;
  height: 40px;
}

.icon-hover:hover {
  background-color: #dadada;
  border-radius: 57px;
  padding-top: 14px;
  padding-left: 14px;
  width: 40px;
  height: 40px;
}

.skills-bg {
  background: #d8e1fe;
  border-radius: 4px;
  color: #000;
}
