.edit_profile_card_page {
  margin: auto;
  width: 100%;
  padding: 30px;
}

.profile_feild_cont {
  padding: 20px;
}
.profile_feild_border {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 10px;
}

.profile_feild_cont > Col {
  padding: 10px;
}
.editprofile_feild_cont {
  padding: 20px;
}
.editprofile_image {
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}
.editprofile_upper_feild {
  align-items: center;
  text-align: center;
  justify-content: center;
}

.profile_edit_model_main {
  min-width: 900px;
}

.profile_edit_model_header {
  height: 50px;
}
.profile_edit_model_header_text {
  margin-top: 10px !important;
  margin-left: 20px;
}

.company_edit_heading {
  color: rgb(0, 87, 248);
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .profile_edit_model_main {
    min-width: 700px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .profile_edit_model_main {
    min-width: 500px;
  }
}
