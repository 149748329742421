@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap);
:root {
  --global-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --global-radius: 10px;
  --global-border: #ebedf2;
  --input-border: #c4c4c4;
  --background-color: #081753;
  --dark--color--sidebar: #323747;
  --theme-color: #081753;
  --theme-color--light: #081753;
  --sidebar--color: #081753;
  --sidebar--color--opacited: #15235C;
  --otp-background-color: #f6f5fa;
  --font-size--inputs: 1rem;
  --font: 'Inter', sans-serif;

}

a {
  text-decoration: none !important;
}

.board_access_indicator {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  /* background-color: var(--sidebar--color); */
}

.header_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: white;
  width:101%;
}

.link_navigation {
  color: black;
  border-bottom: 1px solid transparent;
  /* text-decoration: none; */
}

.link_navigation:hover {
  border-bottom: 1px solid black;
  color: black;

}

html>*,
.font_family_strict,
.font_family_strict>* {
  font-family: 'Inter', sans-serif !important;
  font-family: var(--font) !important;
}

.icon_clickable {
  cursor: pointer;
}

.overflow_y_scoll {
  height: 600px;
  overflow-y: scroll;
}

.pat-20 {
  padding-top: 50px;
}

.info_txt {
  margin-top: 10px;
  color: rgb(153, 152, 152);
}

/* .MuiSwitch-thumb {
  background-color: white !important;
} */

/* .MuiSwitch-track {
  background-color: var(--theme-color--light) !important;
} */
.max_img_container {
  max-width: 150px;
}

.lg_img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.bottom_div_margin_top>div {
  margin-top: 20px;
}

.theme_btn {
  background: #081753 !important;
  background: var(--theme-color--light) !important;
  color: white !important;
}

/* best shadow -10px -15px 90px 0 rgba(0, 0, 0, 0.1) !important */
.ant-tooltip-arrow {
  display: none;
}

.tooltip_card {
  padding-bottom: 0px !important;
}

.tool_green>.ant-tooltip-content>.ant-tooltip-inner {
  color: #389e0d !important;
}

.tool_red>.ant-tooltip-content>.ant-tooltip-inner {
  color: #cf1322 !important;
}

.tooltip_card>.ant-tooltip-content>.ant-tooltip-arrow {
  color: white !important;
  display: none !important;
  border-radius: 6px !important;
}

.root {
  font-family: "Manrope", sans-serif !important;
}

body {
  font-family: "Inter", sans-serif !important;
  /* min-height: 140vh; */
}

.full_card_body {
  min-height: 100vh;
  display: grid;
  place-items: center;
}

/* 
#editor-js {
  width: 100% !important;
} */

.link_full {
  width: 100%;
  text-decoration: none;
  display: flex;
  padding: 10px;
  color: black;
  align-items: center;
}

.link_cont {
  padding: 0px !important;
}

.login_inpt {
  outline: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(143, 143, 143) !important;

  width: 100%;
}

/* 
@media(prefers-reduced-motion:no-preference) {
  .login_inpt:focus-visible {
    transition: outline-offset 2s ease-out;
  }

  .login_inpt:not(:active):focus-visible {
    transition-duration: .25ms;
  }
}

.login_inpt:not(:active):focus-visible {
  outline-offset: 0.15rem;
} */

.login_inpt:focus {
  outline: 1px solid #081753;
  outline: 1px solid var(--theme-color--light);
}

.signup-logo {
  font-size: 30px;
  letter-spacing: 5px;
}

.signup-register-text {
  margin-top: 20px;
  margin-bottom: 10px;
}

.signup_password_cont {
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
}

.signup_password_cont>div:nth-child(1) {
  /* 
  border: 1px solid red; */

  width: 95%;
}

.signup_password_cont>div:nth-child(2) {
  align-items: center;
  text-align: center;
  border: 1px solid #d4d9df;
  width: 40px;
  height: 36.7px;
  margin-top: 29.5px;
  padding-top: 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.signup_password_field {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* .account-pages{
  fon
} */

.form_inpt {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.cur_pt {
  cursor: pointer;
}

.password_inpt {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
  border: 1px solid rgb(143, 143, 143);
  border-radius: 10px;
}

.border_theme {
  border: 1px solid #081753;
  border: 1px solid var(--theme-color--light);
}

.password_inpt>input {
  outline: none;
  width: 90%;
  padding: 10px;
  border-radius: 10px;
  border: none;
}

.form_inpt>i {
  margin-top: 15px;
}

.form_inpt>div>input {
  border: none;
}

.cover_img_md {
  object-fit: cover;
  width: 50px;
  border-radius: 10px;
  height: 50px;
  padding: 2px;
  background-color: #081753;
  background-color: var(--theme-color--light);
}

.md_img {
  object-fit: contain;
  width: 100px;
  height: 100px;
}

.dis_n {
  display: none;
}

.no_margin {
  margin: 0px;
}

.eighty_vh_max_child {
  max-height: 80vh;
  overflow: hidden;
}

.small_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.min_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  /* padding-right: 5px; */
}


.child_col_m_2>.col-12 {
  margin-top: 30px;
}

/* .input_search {
  border: 1px solid hsl(0, 0%, 70%);
} */

.input_search_icon>.select2-selection>.css-yk16xz-control {
  /* border: none;
  outline: none; */
  padding-left: 12px;
}

.input_search_icon>div>.form-control {
  padding-left: 30px;
}

.input_search_icon:active::after,
.input_search_icon:visited::after {
  content: "";
}

.input_search_icon::after {
  position: absolute;
  top: 42px;
  -webkit-transform: rotate(257deg);
          transform: rotate(257deg);
  font-size: 30px !important;
  font-weight: 100 !important;
  left: 0px;
  padding-left: 10px;
  color: #556ee6;
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2315";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  /* color: white; */
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 1rem;
}

.child_col_m_2>.col-12>label {
  margin-bottom: 5px;
}

.uppercase {
  text-transform: uppercase;
}

.small_img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.rounded_small_button {
  /* padding: 5px; */
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 50px;
  display: grid;
  place-items: center;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
}

.rounded_small_button>p {
  margin: 0px !important;
}

.rounded_full {
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
}

.flex_tags {
  display: flex;
  align-items: center;
  border: 1px solid #cacaca;
  border-radius: 10px;
  grid-gap: 4px;
  gap: 4px;
}

.rounded_small_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
  /* object-fit: contain; */
}

.company_img {
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.company_img>img {
  min-width: 100%;
  min-height: 100%;
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
}

.child_col_mt>.col-4 {
  margin-top: 20px;
}

.large_img {
  max-width: 130px;
  border-radius: 10px;
  max-height: 130px;
  object-fit: contain;
}

.small {
  font-size: 10px;
}

.min-wi-8 {
  min-width: 100px;
}

.semi_bold {
  font-weight: 800 !important;
  font-size: 15px;
}

.margin_bottom_sm {
  margin-bottom: 10px;
}

.yellow {
  color: rgb(111, 111, 66);
}

.global_card {
  border-radius: 10px !important;
  border-radius: var(--global-radius) !important;
  cursor: pointer;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  box-shadow: var(--global-shadow);
  transition: 0.5s ease-in-out;
}

.global_card:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.14);
}

.global_gap {
  display: flex !important;
  flex-direction: column !important;
  grid-gap: 30px !important;
  gap: 30px !important;
}

.filter_cont::-webkit-scrollbar {
  display: none;
}

.scrollbar_none::-webkit-scrollbar {
  display: none;
}

@media (max-width: 500px) {
  .filter_cont::-webkit-scrollbar {
    /* width: 2px !important; */
    display: block;
    height: 2px !important;
  }

  .filter_cont::-webkit-scrollbar-track {
    background-color: #ebedf2;
    background-color: var(--global-border);
    margin-left: 4px;
    border-radius: 10px;
  }

  .filter_cont::-webkit-scrollbar-thumb {
    border-radius: 10px;
    margin-top: 4px;
    /* width: 2px !important; */
    background-color: #bbbbbb;
  }
}


.global_scroll::-webkit-scrollbar {
  width: 8px;
}

.global_scroll::-webkit-scrollbar-track {
  background-color: #ebedf2;
  background-color: var(--global-border);
  margin-top: 4px;
  border-radius: 10px;
}

.global_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin-top: 4px;
  background-color: #bbbbbb;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #ebedf2;
  background-color: var(--global-border);
  margin-top: 4px;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin-top: 4px;
  background-color: #bbbbbb;
}

.filter_cont {
  width: auto;
  padding-bottom: 5px;
  display: flex;
  /* background-color: white; */
  grid-gap: 10px;
  gap: 10px;
  overflow-x: scroll;
}

.filter_max_width {
  width: auto;
}

/* 
.filter_cont {
  overflow-y: hidden;
}
*/

.up-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.down-icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.filter {
  position: relative;
  padding: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 999;
  display: grid;
  place-items: center;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid #ebedf2;
  border: 1px solid var(--global-border);
}

.time-zones {
  position: relative;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  z-index: 999;
  display: grid;
  place-items: center;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid #ebedf2;
  border: 1px solid var(--global-border);
}

.selected_time_zones {
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  width: auto;
  display: grid;
  place-items: center;
  padding: 7px 7px;
  background-color: #081753;
  background-color: var(--sidebar--color);
  color: white;
}

.selected_time_zones>span {
  display: flex;
  align-items: center;
}

.icon_filters>i {
  font-size: 18px !important;
  /* font-weight: bold; */
}

.filter>span {
  /* gap: 10px; */
  width: 100px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

.reddish>span {
  width: 100px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

.filter:hover {
  background-color: #ebedf2;
  background-color: var(--global-border);
}

.input_cont {
  margin-left: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.filter_box {
  position: absolute;
  border-radius: 10px;
  box-shadow: 10px 10px 20px -1px rgba(0, 0, 0, 0.1),
    -10px -10px 20px -1px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 20px;
  width: 400px;
  background: white;
  height: 400px;
}

.click_btn_option {
  color: #5d78ff;
  background-color: #ebefff;
  padding: 10px !important;
  outline: none;
  border: none;
  min-width: 40px;
  display: gird;
  place-items: center;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}

.click_btn_option:focus {
  color: white;
  background-color: #5d78ff;
}

.click_btn_option:hover {
  background-color: #5d78ff;
  color: white;
}

@media (max-width: 400px) {
  .filter_box {
    max-width: 100vw !important;
  }
}

@media (max-width: 900px) {
  .filter_box {
    position: absolute;
    top: 150px;
    right: 20px;
  }
}

.share__job {
  position: absolute;
  left: -350px !important;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  box-shadow: var(--global-shadow);
  z-index: 999;
  padding: 20px;
  width: 400px;
  background: white;
  /* height: 400px; */
}

.close_cont {
  position: relative;
}

.close_item {
  position: absolute;
  right: -10px;
  top: -11px;
  cursor: pointer !important;
  width: 20px;
  height: 20px;
  z-index: 9999;
  background-color: #babbba;
  color: white;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

/* 
.he::after {
  content: "";
  top: 0px;
  left: 0px;
  content: '\2606'
} */
.reddish {
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  display: grid;
  place-items: center;
  padding: 5px 0px;
  background-color: #323747;
  background-color: var(--dark--color--sidebar);
  color: white;
}

.reddish>span {
  /* gap: 10px; */
  width: 100px;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.react-kanban-board {
  min-height: 100%;
}

.react-kanban-board>div {
  min-height: 75vh;
}

.react-kanban-board::-webkit-scrollbar {
  width: 3px;
}

.react-kanban-board::-webkit-scrollbar-track {
  background-color: #ebedf2;
  background-color: var(--global-border);
  margin-top: 4px;
  border-radius: 4px;
}

.react-kanban-board::-webkit-scrollbar-thumb {
  border-radius: 4px;
  margin-top: 4px;
  background-color: #bbbbbb;
}

.cover_img_cont {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.company_details {
  display: flex;
  flex-direction: row;
}

.company_details>div:nth-child(2) {
  padding-top: 40px;
  padding-left: 20px;
  font-weight: bold;
}

.company_details>div:nth-child(2)>h4 {
  font-weight: bold;
}

.personal_details {
  border: 1px solid rgb(221, 220, 220);
  margin-right: 10px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
}

.company_job_details {
  border: 1px solid rgb(221, 220, 220);
  border-radius: 5px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.analytics_cont {
  border: 1px solid rgb(221, 220, 220);
  padding: 20px;
  border-radius: 5px;
}

.company-font-weight {
  font-weight: bolder;
}

.company-fonts {
  text-align: center;
}

.cover_left {
  margin-top: 100px !important;
}

.company-text {
  font-size: 20px;
}

.profile_img {
  border-radius: 10px;
  background-color: white;
  object-fit: cover;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.07);
}

.cover_img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  max-height: 150px;
  object-fit: cover;
}

.react-kanban-column {
  transition: 0.8s ease-in-out !important;
  border-radius: 10px !important;
  border-radius: var(--global-radius) !important;
}

/* .react-kanban-column:hover {
  min-height: 400px !important;
  background-color: rgba(212, 239, 255, 0.5) !important;
} */

/* div[data-rbd-draggable-id="column-draggable-5"]:hover {
  background-color: rgba(255, 202, 202, 0.5) !important;
}

div[data-rbd-draggable-id="column-draggable-4"]:hover {
  background-color: rgba(202, 255, 234, 0.5) !important;
} */

/* .div{
   color: purple;
} */
.no_scroll::-webkit-scrollbar {
  display: none;
}

.green_text {
  color: rgb(62, 171, 72);
}

.green_light {
  color: rgb(10, 187, 135);
}

.gap_20px {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.linkedin_btn {
  background-color: rgb(0, 115, 177);
  cursor: pointer;
  outline: none;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 4px;
  display: grid;
  place-items: center;
}

.linkedin_btn>div {
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.width_60 {
  max-width: 60%;
  word-break: break-all;
  word-wrap: break-word;
}

/* .padding_20px */
.small_p {
  color: gray;
  font-size: 10px;
  margin: 0px;
}

.padding_x_20px {
  padding: 10px 10px;
}

.row_margin_20>.row {
  margin-bottom: 20px;
}

.text_gray {
  color: gray;
}

.skill_bar_items {
  background-color: transparent;
  border: 1px solid #fafaff;
  color: white;
}

.edit_btn {
  border: none;
  outline: none;
  background-color: green;
  border-radius: 4px;
  color: white;
}

.remove_btn {
  color: black;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: transparent;
  border: none;
}

.plus_btn_end {
  position: absolute;
  top: 30px;
  right: 10px;
}

.back_icon_container {
  position: relative;
}

.back_icon {
  position: absolute;
  top: 10px;
  cursor: pointer;
  left: 10px;
}

.margin_0 {
  margin: 0px;
}

.custom_btn {
  border: 1px solid #74788d !important;
}

.custom_btn:hover {
  color: white !important;
  background-color: #74788d !important;
}

.custom_btn:focus {
  background-color: #74788d !important;
  color: white !important;
}

.rounded_btn_violet {
  border: 1px solid #081753;
  border: 1px solid var(--background-color);
  background-color: transparent;
  outline: none;
  color: #081753;
  color: var(--background-color);
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  transition: 0.4s ease-in-out;
}

.text-white {
  color: white;
}

.clicked_item_create {
  color: white !important;
  background: #74788d !important;
}

.border_sm_round {
  border-radius: 10px !important;
}

.rounded_btn_violet:hover {
  background-color: #081753;
  background-color: var(--background-color);
  opacity: 0.9;
  color: white;
}

.rounded_btn_violet_click {
  background-color: #081753 !important;
  background-color: var(--background-color) !important;
  color: white !important;
}

.rounded_click {
  border: 1px solid #081753;
  border: 1px solid var(--background-color);
  /* background-color: transparent; */
  outline: none;
  /* color: var(--background-color); */
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  transition: 0.4s ease-in-out;
  background-color: #081753 !important;
  background-color: var(--background-color) !important;
  color: white !important;
}

.bottom_click {
  background-color: white;
  box-shadow: 0px 10px 20px -1px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
  z-index: 999;
  position: relative;
}

.bottom_click::after {
  position: absolute;
  content: "";
  bottom: 1px;
  width: 0%;
  left: 50%;
  z-index: 970;
  transition: 0.4s ease-in-out;
  border-radius: 10px;
  height: 4px;
}

.bottom_click:hover::after {
  width: 98%;
  left: 0px;
  background-color: #081753;
  background-color: var(--theme-color);
}

.bottom_click:focus::after {
  width: 100%;
  left: 0px;
  background-color: #081753;
  background-color: var(--theme-color);
}

.theme_btn {
  outline: none;
  border: none;
  padding: 10px;
  background-color: #081753;
  background-color: var(--theme-color);
  border-radius: 10px;
  box-shadow: 0px 10px 20px -1px rgba(0, 0, 0, 0.1);
  color: white;
}

.blue_btn {
  background-color: #081753 !important;
  background-color: var(--background-color) !important;
  color: white !important;
}

.filter_data {
  /* font-size: 15px !important; */
  text-transform: capitalize;
  margin: 0px;
}

.filter_data_box {
  /* font-size: 15px !important; */
  padding: 10px 0px;
  margin: 0px;
  text-transform: uppercase;
  margin: 0px;
}

.bg_white {
  background-color: white;
}

input[type="checkbox"] {
  cursor: pointer !important;
}

.skills>span {
  padding: 5px;
}

.h6info {
  margin-top: 5px !important;
  font-size: 12px !important;
}

.violet {
  color: #081753;
  color: var(--background-color);
  background-color: rgba(200, 126, 246, 0.1) !important;
}

iframe {
  pointer-events: none;
}

.job_title_input:focus {
  border: 1px solid black !important;
}

.flex_wrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

#education_qual {
  margin-bottom: 20px;
  outline: none !important;
}

#education_qual:focus {
  outline: none !important;
}

#education_qual>*:hover {
  outline: none !important;
  border: none !important;
}

.border_red {
  border: 1px solid red !important;
}

.text_sm_gray {
  color: gray;
  font-size: 10px;
}

.child_span_margin>span {
  margin-right: 10px;
  padding: 5px !important;
}

.location_table_cell {
  color: gray;
  text-transform: uppercase;
}

.commission_column {
  color: #aeb1b8;
}

.commission_column>span {
  padding: 10px;
  font-size: 12px !important;
}

.active_td>span {
  padding: 5px 10px !important;
  font-size: 11px !important;
  font-weight: 1000 !important;
  border-radius: 4px !important;
}

.h_or_p {
  margin: 0px !important;
}

.no_border_inpt {
  border: none !important;
}

.currency_inpt {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-left: 5px;
}

.PhoneInputInput {
  padding: 10px !important;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.view_more_btn_job {
  background-color: #081753;
  background-color: var(--theme-color--light);
  color: white;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 1000;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.cover_pages {
  padding-top: 68px;
}

.map_list {
  background-color: white;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  padding: 0px;

  border-bottom-right-radius: 10px;
}

.map_items {
  list-style-type: none;
  padding: 10px;
}

.map_items:hover {
  background-color: #e8e9eb7d;
}

.loader {
  position: relative;
}

.loader::after {
  content: "";
  background-color: #081753;
  background-color: var(--theme-color--light);
  position: absolute;
  width: 20px;
  border-radius: 50px;
  height: 20px;
  -webkit-animation: 1s scaler infinite;
          animation: 1s scaler infinite;
}

.loader::before {
  content: "";
  background-color: #081753;
  background-color: var(--theme-color--light);

  position: absolute;
  top: 5px;
  left: 5px;

  border-radius: 50px;
  width: 10px;
  height: 10px;
  -webkit-animation: 1.8s secondary infinite;
          animation: 1.8s secondary infinite;
}

@-webkit-keyframes scaler {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1.9);
            transform: scale(1.9);
    opacity: 0;
  }
}

@keyframes scaler {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1.9);
            transform: scale(1.9);
    opacity: 0;
  }
}

@-webkit-keyframes secondary {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes secondary {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

.PhoneInputCountry {
  margin: 4px 0px;
  padding-right: 5px;
  /* border-right: 1px solid var(--input-border); */
}

.disabled_btn {
  opacity: 0.4;
  background-color: #081753;
  background-color: var(--theme-color--light);
}

.no_content_full_screen {
  width: 100%;
  min-height: 80vh;
  display: grid;
  place-items: center;
}

.search_bar_fliter {
  border: 1px solid black !important;
  border-radius: 2px !important;
  color: black !important;
}

.skill_badge {
  border: 1px solid rgb(235, 237, 242);
  background: rgb(241, 242, 247);
  padding: 5px;
  border-radius: 5px;
}

.react-kanban-column>div:nth-child(2) {
  overflow-y: scroll !important;
  height: 80vh !important;
}

.remove_text {
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kanban_left_icon {
  font-weight: bold;

}

.w-seventy {
  width: "70%"
}

.header_jobs_sticky {
  position: -webkit-sticky;
  position: sticky;

  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  top: 58px;
  background: white;
  z-index: 1000;
  padding: 10px 0px;
}

.nav_tabs_custom {
  height: 10px;
}

.job_user_card_information {
  cursor: pointer;
}

.job_user_card_information:hover {
  background: rgba(255, 255, 255, 0.5);
}

.rdw-editor-toolbar {
  border: '1px solid rgba(0,0,0,0.5) !important'
}

.rdw-editor-main {
  border: '1px solid rgba(0,0,0,0.5) !important'
}
.forget_password_container {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.forget_password_elem_container {
    width: 30vw;
    /* height: 88vh; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    grid-gap: 30px;
    gap: 30px;

}

@media (max-width:800px) {
    .forget_password_elem_container {
        width: 60vw;
    }
}

@media (max-width:1200px) {
    .forget_password_elem_container {
        width: 60vw;
    }
}

.forget_password_image {
    width: 180px;
}
.assigned_jobs {
  display: flex;
  min-height: 500px;
  margin-top: 20px;
  justify-content: space-around;
  /* height: 80vh; */
}

.anticon-rocket {
  font-size: 70px !important;
  font-weight: bold;
  -webkit-transform: rotate(50deg);
          transform: rotate(50deg);
}

.assigned_container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.job_cont {
  /* padding-top: 10px; */
  margin-bottom: 20px;
}

.reward_tab {
  display: flex;
  margin-top: 8px;
  align-items: center;
  justify-content: flex-start;
}

.reward_tab > div {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.job_info_tab {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .job_cont {
    margin-bottom: 10px;
  }
}

.popup_head {
  background-color: rgba(93, 120, 255, 0.1);
  padding: 8px 0px;
  width: 100%;
  color: var(--background-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
}

.popup_head > div {
  font-size: 30px;
  padding: 6px;
  display: grid;
  place-items: center;
  color: white;
  position: relative;
  border-radius: 50%;
  background-color: var(--background-color);
}

.popup_head > div > i {
  font-size: 18px !important;
}

.popup_head > div:nth-child(1)::after,
.popup_head > div:nth-child(2)::after {
  top: 49%;
  right: -100px;
  position: absolute;
  content: "";
  height: 3px;
  width: 100px;
  background-color: var(--background-color);
}

.skill_box {
  padding: 10px !important;
  border: 1px solid #bababa;
}

.labels_img {
  margin: 0px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

.job_detals_model_main {
  margin-left: -22px !important;
  margin-bottom: -15px !important;
  box-shadow: rgba(185, 183, 183, 0.15) 6px -7px 0px 7px;
  /* border-radius: 5px; */
}

.job_detals_model_left_cont {
  border-right: 1px solid rgb(208, 204, 204);
}

.job_detals_model_right_cont {
  padding: 25px;
}

.job_detals_model_heder_head {
  min-height: 100px;
  border-bottom: 1px solid rgb(208, 204, 204);
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.job_detals_model_heder_head > div:nth-child(1) {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(208, 204, 204);
  border-radius: 3px;
  margin-top: 5px;
}

.job_detals_model_heder_head > div:nth-child(2) {
  width: 75%;
  height: 100%;
}

.job_detals_model_heder_head > div:nth-child(3) {
  width: 50px;
  height: 100%;
  margin-top: 0px;
}

.job_detals_model_heder_head > div:nth-child(3) > Button {
  border-radius: 50%;
  font-size: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.job_details_img {
  width: 100%;
  height: 100%;
}

.job_detals_model_bottom {
  min-height: 80px;
  border-top: 1px solid rgb(208, 204, 204);
  border-bottom: 1px solid rgb(208, 204, 204);
  margin-bottom: 10px;
  justify-content: left;
  align-items: left;
  padding-top: 10px;
}

.job_details_contents {
  padding: 20px;
}

.jobs_name_for_model {
  padding: 2px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  gap: 5px;
}

.jobs_name_for_model > p {
  background: rgb(94, 210, 94);
  padding: 5px;
  border-radius: 14px;
  color: white;
}

.about_you_on_jobs_heading {
  font-weight: bolder;
}

.job_detals_model_right_green_box {
  min-height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(212, 230, 248);
  color: var(--theme-color--light);
  border-radius: 10px;
  margin: auto;
}

.job_detals_model_right_green_box > h5 {
  font-weight: bold;
}

.job_detals_model_right_green_box > button {
  width: 150px;
  color: white;
  background-color: var(--theme-color--light);
  text-align: center;
  font-weight: bold;
  margin: auto;
}

.job_details_loacations_main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  gap: 10px;
}

.job_details_loacations {
  border: 1px solid rgb(208, 204, 204);
  padding: 6px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(235, 234, 234);
}

.job_details_view_more {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
}

.job_details_view_more > Button {
  margin: auto;
  width: 140px;
  font-weight: bold;
  padding: 10px;
}

.details_of_right_side {
  font-weight: bold;
  font-size: 15px;
}

.job__addition {
  max-width: 100%;
  display: flex;
  margin-left: 10px;
  grid-gap: 10px;
  gap: 10px;
  /* min-height: 450px; */
  border-radius: 4px;
  flex-direction: column;
  height: 100%;
  padding: 10px 20px;

  background-color: #f8faff;
  box-shadow: var(--global-shadow);
  border-radius: var(--global-radius);
  padding-bottom: 20px !important;
}

.screened_application_topbar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  gap: 30px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .screened_application_topbar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    gap: 30px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .screened_application_topbar {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    gap: 30px;
  }
}

.job_head_sub {
  display: flex;
  align-items: center;
  font-size: 11px !important;
  justify-content: space-between;
}

.job_filter_text {
  display: block;
}

.height_full {
  position: relative;
  height: auto;
}

.job__addition>.down_icon {
  display: none;
}

.job_small_addition {
  overflow-y: scroll;
  max-width: 100%;
  display: flex;
  margin-left: 10px;
  grid-gap: 10px;
  gap: 10px;
  /* max-height: 90vh; */
  overflow: hidden;
  border-radius: 4px;
  flex-direction: column;
  padding: 10px 20px;

  background-color: #f8faff;
  box-shadow: var(--global-shadow);
  border-radius: var(--global-radius);
  padding-bottom: 20px !important;
}

.down_icon,
.down_icon_large {
  border: none;
  padding: 10px;
  display: grid;
  place-items: center;
  outline: none;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 50%;
}

.ant-btn {
  background-color: var(--background-color) !important;
  /* height: 80px !important; */
  color: white !important;
  border-radius: 4px !important;
}

.job_lists {
  display: flex;
  flex-direction: column;
}

.job_list_header {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--global-radius);
  box-shadow: var(--global-shadow);
  padding-left: 10px;
  height: 50px;
  font-weight: 900;

}

.job_list_header>h4 {
  margin: 0px;
}

.single_job {
  padding: 10px 20px;
  display: flex;
  /* width: 200px; */
  grid-gap: 10px;
  gap: 10px;
  background-color: white;
  border-radius: var(--global-radius);
  align-items: center;
  box-shadow: var(--global-shadow);
  min-height: 150px;
}

.card_full_width {
  width: 100%;
}

.single_job:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.comp_img {
  border-radius: 50%;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.comp_img_cont {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.job_head_main {
  display: flex;

  grid-gap: 10px;

  gap: 10px;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.job_head>.job_head_main>h4 {
  cursor: pointer;
  padding-top: 4px;
  font-weight: 700;
}

.job_head>.job_head_main>.anticon:hover {
  color: rgb(208, 143, 15);
}

.job_head>.job_head_main>h4:hover {
  color: var(--background-color);
}

.job_head_right {
  position: absolute;
  top: 0px;
  right: -10px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  justify-self: flex-end;
}

.ant-tooltip-inner {
  color: black !important;
}

.job_section {
  display: flex;
  justify-content: space-between;
  /* gap: 8px; */
  /* flex-direction: column; */
}

.job_section_desc {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

/* 
.job_section_desc:nth-child(2):after,
.job_section_desc:nth-child(3)::after {
  content: "";
  left: -10px;
  position: absolute;
  top: 5px;
  width: 1px;
  height: 20px;
  background-color: grey;
} */

.total_num {
  font-weight: 500;
  font-size: 14px;
}

.single_job_footer {
  display: flex;
  justify-content: flex-end;
}

.single_job_tag {
  cursor: pointer;
  border-radius: 4px;
}

.single_job_tag:hover {
  opacity: 0.9;
}

.cursor {
  cursor: pointer;
}

.border_bottom {
  border-bottom: 1px solid var(--global-border);
  padding-bottom: 10px;
  width: 100%;
}

.bg_global {
  color: var(--background-color) !important;
  background-color: #d0d8ff !important;
}

.bg_global:hover {
  background-color: var(--background-color);
  color: white;
}

.w_fourty {
  min-width: 40%;
}

.btn_container {
  display: flex;
  justify-content: space-between !important;
}

.job_list_wrap {
  width: 100%;
  /* position: relative; */
}

.add_new_jobs_btn {
  background-color: #5d78ff;
  color: white;
  padding: 10px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.job_filter_names {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  grid-gap: 10px;
  gap: 10px;
}

.job_filter_radio {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.job_filter_radio>label {
  margin: 0px !important;
  font-weight: 400;
}

.job_filter_radio>input {
  /* display: flex; */
  /* align-items: flex-end; */
  /* margin: 0px !important; */
  margin-right: 10px;
}

.invite_profile_card {
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px !important;
  gap: 20px !important;
  align-items: center;
}

.invite_profile_round {
  width: 80px;
  height: 80px;
  background-color: #fd397a1a;
  color: #fd397a;
  font-weight: bolder;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.invite_profile_round>span {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.invite_profile_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 2px;
  gap: 2px;
}

.data_edit {
  border: none !important;
  margin-left: 4px;
}

.candidate_email {
  font-size: 14px;
  min-height: auto !important;
}

.filter_horizontal {
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-around;
}

#screened_application_sidebar {
  box-shadow: 0px 0px 5px rgb(56 152 226 / 30%);
}

.basic-4 {
  background: linear-gradient(currentColor 0 0) 0 100% / 0 1px no-repeat;
  background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0) 1px no-repeat;
  transition: 0.3s, background-position 0s 0.3s;
}

.basic-4:hover {
  --d: 100%;
  --p: 100%;
}
.cursor-pointer {
  cursor: pointer;
}

.job_head_right {
  position: absolute;
  top: 16px;
  right: 10px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  justify-self: flex-end;
}

.card-grid-2 {
  border-radius: 10px;
  border: 0.88px solid rgba(6, 18, 36, 0.1);
  overflow: hidden;
  min-height: 213px;
  margin-bottom: 30px;
  position: relative;
  background: #ffffff;
}

.hover-up {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.card-grid-2:hover {
  box-shadow: 0px 9px 26px 0px rgb(31 31 51 / 6%) !important;
  border-color: #b4c0e0 !important;
}

.card-grid-2-img-small {
  background-color: #5aa6ff;
  height: 48px;
  width: 48px;
  border-radius: 12px;
}

.job-name {
  display: inline-block;
  color: #1c58f2;
  font-size: 14px;
  font-weight: 600;
}

.tags-names {
  border-radius: 10px;
}

.btn-grey-small {
  background-color: rgba(151, 119, 250, 0.12);
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 6px;
  color: #37404e;
}

.nav__container {
  width: 100vw;
}

.dashboard_wrapper {
  display: flex;
  /* flex-direction: ; */
  margin-top: 120px;
  /* flex-wrap: wrap; */
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.tab_bar {
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
}

.tab_bar::-webkit-scrollbar {
  display: none;
}


.clicked_tab {
  background-color: #021a39;
  color: white;
  /* background-color: var(--dark--color--sidebar); */
}

.revert {
  /* transform: rotate(0deg); */
  border: 2px solid rgb(193, 85, 85);
  border-radius: 3px;
}

.img {
  height: 70px;
}

.header_job_h5 {
  text-transform: uppercase;
  /* font-weight: bolder; */
}

.job_info_card {
  display: flex;
  color: gray;
  font-size: 10px;
  justify-content: flex-start;
  align-items: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

.job_info_card>button {
  border: none;
  background-color: transparent;
  outline: none;
  margin-bottom: 0.9rem;
  margin-left: auto;
}

.link_btn_preview {
  color: #50a5f1 !important;
}

.link_btn_preview:hover {
  border: none !important;
}

@media (max-width: 9n80px) {
  .global_card {
    min-width: 100% !important;
  }
}
#job-list-excess-control-main {
  /* border: 1px solid red; */
  background-color: #ebebeb;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

#joblist-search-main {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
#joblist-search-main > div:nth-child(1) {
  width: 30%;
}
#joblist-search-main > div:nth-child(2) {
  width: 20%;
}
#joblist-search-main > div:nth-child(3) {
  width: 20%;
}
#joblist-search-main > div:nth-child(4) {
  width: 20%;
}
#joblist-search-main > div:nth-child(5) {
  width: 10%;
}

#joblist-action-main {
  /* border: 1px solid red; */
  padding: 10px;
  display: flex;
}
#joblist-action-main > div {
  /* border: 1px solid red; */
}

#joblist-action-main > div:nth-child(1) {
  width: 60%;
}
#joblist-action-main > div:nth-child(2) {
  width: 25%;
  font-size: 10px !important;
}
#joblist-action-main > div:nth-child(3) {
  width: 15%;
}

.joblist-required-checkbox {
  background: #1890ff;
  /* padding: 3px !important;  */
  border-radius: 5px;
  color: white !important;
  padding: 3px !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.joblist-saveforlater-btn {
  padding: 3px !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.joblist-save-btn {
  padding: 5px !important;
  font-size: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: auto;
}

.joblist-share-btn {
  padding: 4px !important;
  padding-top: 1px !important;
  border-radius: 50% !important;
  margin-left: 4px;
  height: 26px !important;
  /* padding-bottom: 10px !important; */
  font-size: 15px !important;
}
.joblist-share-btn > span {
  margin-top: -5px !important;
}

#joblist-table-jobtitle-cont {
  width: 250px;
}
#joblist-table-jobtitle-cont > div:nth-child(1) {
  font-size: 18px;
}
#joblist-table-jobtitle-cont > div:nth-child(1):hover {
  text-decoration: underline;
  font-size: 18px;
}

.joblist-table-jobtitle-alert1-cont {
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
  background: #51a5f1;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  color: white;
}
.joblist-table-jobtitle-alert2-cont {
  height: 20px;
  border-radius: 5px;
  background: #ddf2f2;
  font-size: 11px;
  color: #095b5b;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
}

#job-close-model-image-cont {
  /* border: 1px solid red; */
  height: 100px;
  width: 100px;
  margin: auto;
  margin-bottom: 20px;
}

#job-close-model-image-cont > img {
  width: 100%;
  height: 100%;
}

#job-close-model-btton-cont {
  padding: 10px;
  justify-items: space-around;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.col_btn {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
  /* gap:10px; */
}

.green {
  color: green;
}

.loader_icon {
    -webkit-animation-name: rotate_icon;
            animation-name: rotate_icon;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

@-webkit-keyframes rotate_icon {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotate_icon {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.editor_blog {
  width: 100%;
  height: 100%;
}

.blog_title {
  width: 100%;
  padding: 20px;
}

.editing_section {
  /* height: 100vh; */
  /* max-width: 70vh; */
  overflow: hidden;
  margin-top: 40px;
  background-color: white;
  padding-bottom: 100px;
  display: grid;
  place-items: center;
}

.editing_section>div {
  display: flex;
  /* max-width: 70vw; */
  flex-direction: column;
  padding-left: 20px;
  /* align-items: center; */
  grid-gap: 20px;
  gap: 20px;
}

.length_text {
  color: gray;
}

.title {
  display: flex;
  position: relative;

  align-items: center;
  min-height: 100px;
  height: auto;
  /* align-items:; */
}

.image_placer {
  /* max-height: 300px; */
  object-fit: contain;
}

/* .image_adder>img:nth-child(2) {
    display: none;
} */

.image_adder>img {
  max-width: 300px;
}

.no_image {
  visibility: hidden;
  height: 0px !important;
  overflow: hidden;
}

.add_icon {
  position: absolute;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  left: -10%;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  padding: 5px;
  place-items: center;
}


.title>textarea {
  margin-top: 50px;
  background-color: transparent;
  resize: none;
  outline: none;
  height: 100%;
  max-width: 600px;
  width: 100%;
  height: auto;
  /* overflow:inherit; */

  word-wrap: break-word;
  page-break-after: always;
  -webkit-column-break-after: always;
          break-after: always;
  overflow-wrap: break-word;
  font-weight: bolder;
  font-size: 2.5rem;
  border: none;
}

.title>textarea::-webkit-scrollbar {
  display: none;
}

.ce-block__content {
  margin: 0px !important;
}

.title>textarea::-webkit-input-placeholder {
  /* text-rendering: geometricPrecision; */
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 2.5rem;

}

.title>textarea::placeholder {
  /* text-rendering: geometricPrecision; */
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 2.5rem;

}

.ce-paragraph::before {
  color: gray;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 3rem;

}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: text;
  display: block;
  color: gray;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 1rem;

  /* For Firefox */
}

.short_desc>textarea,
.short_desc>div[contenteditable="true"] {
  background-color: transparent;
  outline: none;
  height: 100%;
  resize: none;
  width: 100%;
  font-size: 1rem;
  max-width: 600px;
  border: none;
}

.short_desc>textarea::-webkit-input-placeholder {
  /* text-rendering: geometricPrecision; */
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 1rem;

}

.short_desc>textarea::placeholder {
  /* text-rendering: geometricPrecision; */
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 1rem;

}

.form_submit {
  margin-top: 20px;
  background: none;
  align-self: flex-start;
  padding: 10px 30px;
  border: none;
  /* width: 350px; */
  /* font-size: ; */
  display: flex;
  justify-content: flex-start;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  cursor: pointer;
}

.form_submit:after,
.form_submit:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 2px solid #021a39;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.form_submit:after {
  -webkit-transform: translate(3px, 3px);
          transform: translate(3px, 3px);
}

.form_submit:before {
  -webkit-transform: translate(-3px, -3px);
          transform: translate(-3px, -3px);
}

.form_submit:hover:after,
.form_submit:hover:before {
  -webkit-transform: translate(0);
          transform: translate(0);
}

.category_section>div:last-child {
  margin-right: 50px;
}

.category_container {
  display: flex;
  position: relative;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  max-width: 800px;
  font-size: 12px;
}

.left_arrow {
  position: absolute;
  top: 0%;
  left: -5%;
  height: 100%;
  /* margin-right: 30px; */
  width: 20px;
  background-color: white;
  opacity: 1;
  /* opacity: 1; */
  box-shadow: 40px 0px 20px rgba(255, 255, 255, 1);
  /* padding-right: 20px; */
  /* left: 0px; */
}

.right_arrow {
  position: absolute;
  top: 0%;
  padding-left: 20px;
  background-color: white;
  box-shadow: -20px 0px 20px rgba(255, 255, 255, 1);
  right: 0px;
}

.left_arrow,
.right_arrow {
  display: grid;
  place-items: center;
  height: 100%;
  cursor: pointer;
}

.category_section {
  display: flex;
  position: relative;
  align-items: center;
  scroll-behavior: smooth;
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 20px;
  /* max-width: 40vw; */
  overflow-x: scroll;
  padding-bottom: 10px;
}

.category_section>div {
  border: 1px solid #021a39;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 4px;
}

.category_section::-webkit-scrollbar {
  display: none;
}

.clicked_btns {
  background-color: #021a39;
  color: white;
}

#editor-js>a,
#editor-js>*>a,
.ce-block__content>*>a {
  color: black;
  font-weight: bolder;
}
.comment_scrollable_area {
  max-height: 80vh;
  height: 80vh;
  overflow-y: scroll;
}

.comment_time {
  color: gray;
  font-size: 10px;
}

.send_comments {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.recived_comments {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.send_details {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.input_comment {
  position: absolute;
  bottom: -10px;
  background-color: white;

}
.blog_catgory_card_thumbnail {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  object-position: center;
}

.card_categories {
  overflow: hidden;
  position: relative;
  max-height: 250px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.1);
}

.category_card_name {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding: 0px 10px;
  background-color: white;
  left: 0;
}

.del_btn {
  outline: none;
  border: none;
  color: red;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: rgb(255, 157, 157);
}

.formLable {
  padding: 14px !important;
  background-color: #f5f7f9 !important;
  border: 0px !important;
  border-radius: 8px !important;
}

.loader_container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.place_items_center {
  place-items: center;
}
.no_height {
  height: 0px;
  /* display: none; */
}

.long_filter {
  /* background-color: white; */
  display: flex;
  height: 100px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.form_company > .row {
  padding: 5px 0px;
}

/* .long_filter {
    border-radius: 10px;
    cursor: pointer;
    width: 50px;
    padding: 4px 8px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
}

.long_filter>span {
    display: flex;
    gap: 4px;
    align-items: center;
}

.long_filter:hover {
    background-color: #f4f4f5;

}

.clicked_filter {
    background-color: #f4f4f5 !important;
}

.long_filter_data {
    transform: translateY(-100%);
    z-index: -999 !important;
    overflow: scroll;
    transition: 0.4s ease-in-out;
    padding-right: 20px;
    height: 10px;
} */

.long_items {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  flex-wrap: wrap;
}

.filter_items {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-around;
}

.filter__container {
  background-color: white;
  margin-bottom: 10px;
  position: relative;
  border-radius: 10px;
}

.translate_100 {
  position: absolute;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.translate_0 {
  position: absolute;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.table_row {
  background-color: white;
  margin: 10px 0px;
  border-radius: 10px;
}

.table_item {
  padding: 10px !important;
  /* display: grid; */
  /* place-items: center; */
}

.talent__box {
  width: 100%;
  height: 150px;
  box-shadow: var(--global-shadow);
  background-color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: var(--global-radius);
  transition: 0.4s ease-in-out;
}

.talent__box:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.talent_inner_border {
  border-bottom: 1px solid #acacac;
  margin-bottom: 10px;
}

.talent_inner_border > h4:hover {
  color: var(--background-color);
}

.talent_filter {
  overflow-x: hidden;
  /* max-width: 90vh; */
  /* overflow: scroll; */
}

.talent_header {
  background-color: white;
  box-shadow: var(--global-shadow);
  border-radius: var(--global-radius);
  height: 80px;
  align-items: center;
}

.talent_table {
  border-radius: var(--global-radius) !important;
  margin-top: 10px !important;
}

.outlined_btn {
  background-color: var(--global-border);
  display: grid;
  cursor: pointer;
  place-items: center;
  height: 30px;
  width: 100% !important;
  border-radius: var(--global-radius);
}

.nav_icon {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.talent_filter_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #a29e9e;
}

.talent_filter_info {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.talent_info > .row {
  padding-bottom: 10px !important;
}

.inpt__filter > input {
  margin-right: 10px !important;
}

.talent_info {
  max-height: 80vh;
  overflow-x: hidden;
  padding-bottom: 50px;
  overflow: scroll;
}

.two_btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 20px;
  gap: 20px;
}

.question_list_item {
  background-color: white;
  /* box-shadow: 0px 10px 20px -1px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  min-height: 80px;
  display: grid;
  margin-bottom: 10px !important;
  transition: 0.3s ease-in-out;
}

.question_list_item:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 10px 10px 20px rgba(0, 0, 0.4);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.delete_icon {
  background-color: hsla(0, 86%, 69%, 0.18) !important;
}

.edit_icon {
  background-color: rgba(52, 195, 143, 0.18) !important;
}

.icon_box {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background-color: white;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}

.icon_box:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.btn_4 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

.option_effects {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* gap:10px; */
}

.option_action > div {
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
}

.link_box {
  background-color: #e3eaed;
}

.email_header {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.email_header > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.thumb_holder {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.thumb_holder > i {
  color: rgb(10, 187, 135);
}

.thumb_holder > span {
  font-size: 16px;
  font-weight: 800;
}

.email_header_container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  /* border-radius: 10px; */
  border-bottom: 1px solid #dbdbdb;

  padding: 10px;
  align-items: center;
}

.email_container_header {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
  align-items: center;
}

.email_container_header > div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.modal_overide > .modal-content {
  background-color: #f6f6f6 !important;
}

.email_container {
  margin-top: 20px;
  /* border: 1px solid #bababa; */
  background-color: white;
  /* border-bottom: 1px solid #dbdbdb; */

  padding: 10px;
  /* background-color: #f6f6f6; */
  /* box-shadow: 10px 10px 20px -1px rgba(0, 0, 0, 0.1), -10px -10px 20px -1px rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  min-height: 60vh;
}

.email_header_content {
  font-size: 15px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.email_sender_details {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-between;
}

.email_header_details {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.email_sender_details > div > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.first_email_header {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.sender_name {
  font-weight: 600;
}

.email_content {
  padding: 20px 10px;
}

.email_data {
  background-color: white;
  /* border-radius: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.report_sidebar {
  width: 100%;
  float: left;
  padding: 20px;
  min-height: 80vh;
  border-radius: 5px;
}

.candidate_card {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  margin-bottom: 20px;
  /* box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1); */
  margin-top: 10px;
}

.candidate_card > h6 {
  color: gray;
}

.candidate_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.active_data {
  color: green !important;
}

.settings_header {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
}

.alert_small {
  width: 200px;
}

.bold_success {
  font-weight: bold;
  margin: 0px;
}

.alert_small_comp {
  margin: 0px !important;
  display: grid;
  place-items: center;
}

.settings_header_first {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.settings_header_profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-weight: bold;
  display: grid;
  place-items: center;
  color: var(--background-color);
  background-color: #e1f5fe;
}

.settings_header_profile>img {
  border-radius: 50%;
}

.settings_header_right {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.email_brands {

  padding: 10px 20px;
  position: relative;
  margin: 0px !important;
  /* justify-content: space-between; */
}

.email_brands::after {
  content: "";
  left: 0px;
  position: absolute;
  width: 4px;
  /* border-radius: 10px; */
  transition: 0.5s ease-in-out;
  top: 50%;
  height: 0%;
  background-color: var(--background-color);
}

.email_brand_div:hover::after {
  top: 0px;
  height: 100%;
  background-color: var(--background-color);
}

.brand_wrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.brand_wrapper>img,
.brand_wrapper>.icon {
  width: 30px;
  object-fit: contain;
  display: grid;
  place-items: center;
  height: 30px;
}

.icon {
  border: 1px solid #3c3c3c;
  border-radius: 4px;
}

.icon>i {
  font-size: 20px;
}

.brand_wrapper>.pofile_banner {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-weight: bold;
  display: grid;
  place-items: center;
  color: var(--background-color);
  background-color: #e1f5fe;
}

.email_wrapper {
  padding: 10px;
}

.setting-job-staff-pages-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.setting-job-staff-pages-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.email_brand_div {
  padding: 6px 20px;
  background-color: white;
  border-bottom: 1px solid #eaeaea !important;
}

.email_brand_div>p {
  margin: 0px !important;
}

.company_grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company_grid>p {
  margin: 0px !important;
}

.calender_grid {
  /* display: grid; */
  /* place-items: center; */
  padding: 40px;
}

/* don't change */
.new_calender_grid {
  display: grid;
  height: 100%;
  place-items: center;
}

.calender_contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calender_contents>i {
  font-size: 40px;
}

.text_area_like {
  padding: 20px;
  border: 1px solid #bababa;
  border-radius: 10px;
}

.edit_del_btn {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.no_data_staff {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.integrations_card {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 230px;
}

.integrations_card > div:nth-child(1) {
  width: 98%;
  margin: auto;
  align-content: flex-end;
  align-items: flex-end;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
}

.integrations_card_headers > div:nth-child(1) {
  padding-top: 20px;
}
.integrations_card_headers > div:nth-child(1) > div > h4 {
  font-weight: bolder;
  font-size: 20px;
}
.integrations_card_headers > div:nth-child(1) > div > a {
  font-size: 16px;
  color: rgb(120, 115, 115);
}
.integration_button {
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  align-self: flex-end;
  padding: 5px;
  background-color: transparent;
  outline: none;
  /* width: 70%; */
}

.integration_button:hover {
  color: #2a9cff;
  border: 1px solid #2a9cff;
}

.integration_main_div {
  background: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

.setting_integrations_cards_cont {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
  padding: 10px;

  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
  padding: 10px;
}

.integrations_card_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.integration_card_bottom {
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  padding: 20px;
  align-content: space-between !important;
  justify-items: center;
  display: flex;
}

.integration_card_bottom > div:nth-child(2) {
  padding-top: 5px;
}

.small_integration_img_cont {
  padding: 0px;
  width: 60px;
  height: 60px;
}

.small_integration_img {
  width: 100%;
  height: 100%;
  margin-top: -13px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .setting_integrations_cards_cont {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .setting_integrations_cards_cont {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1500px) {
  .setting_integrations_cards_cont {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
  }
}

.settings_header_profile_details_cont {
  margin-top: 10px;
}

.settings_header_profile_details_cont > h4 {
  font-size: 20px;
  padding-left: 5px;
}

.settings_header_profile_details_cont > h4 > span {
  color: #325684;
  font-size: 10px;
}

.settings_header_profile_details_cont > p {
  margin: 5px;
}

.profile_download_btn {
  text-align: right;
}

#profile_background_main_cont {
  width: 550px;
  height: 350px;
  margin: auto;
}

#profile_background_image {
  border-radius: 10px;
  width: 550px;
  height: 350px;
  /* border: 1px solid red; */
  margin: auto;
}

.profile_overlay {
  /* border: 1px solid red; */
  margin-top: -340px;
  padding: 20px;
  /* margin: auto; */
}

.card-overlay {
  border-radius: 10px;
  height: 325px;
}

.candidate-price {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}

.candidate-name {
  font-weight: 600;
  color: #fff;
  font-size: 16px;
}

.candidate-designation {
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.hire-me-btn {
  border: 1px solid #fff !important;
  color: #fff !important;
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
}

.hire-me-btn:hover {
  border: 1px solid #fff;
  color: #99b2d4;
  /* font-size: 15px; */
  font-weight: 600;
  background-color: transparent;
}

.btn-download:hover {
  color: #99b2d4;
  /* font-size: 15px; */
  font-weight: 600;
  background-color: #000;
}

.btn-download {
  color: #fff !important;
  border: 1px solid #fff !important;
  /* font-size: 15px; */
  font-weight: 600;
  background-color: #000;
}

.location-custom-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgb(56 152 226 / 30%);
}

.about-me {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.user-desc {
  color: rgb(58, 58, 58);
  font-size: 14px;
  font-weight: 450;
}

.skills {
  padding: 5px 15px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #000;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.list-date {
  font-size: 13px;
  color: #626262;
}

.list-title {
  color: #1967d2;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.list-position {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.dotted-pointer {
  width: 20px;
  height: 20px;
  border: 3px solid #dbe6f7;
  border-radius: 50%;
  background-color: #1967d2;
  content: "";
  position: relative;
  left: -45px;
  top: 46px;
}

.line {
  width: 20px;
  height: 1px;
  background-color: #1967d2;
  position: relative;
  left: 18px;
  top: 7px;
}

.text-lable {
  font-size: 14px;
  color: #666666;
}

.text-value {
  font-size: 15px;
  color: #17171d;
  word-break: break-word;
  font-weight: 500;
}

.icon-color {
  color: #1967d2;
  font-size: 18px;
}

.icon-hover {
  /* background-color: #dadada; */
  padding-top: 14px;
  padding-left: 14px;
  width: 40px;
  height: 40px;
}

.icon-hover:hover {
  background-color: #dadada;
  border-radius: 57px;
  padding-top: 14px;
  padding-left: 14px;
  width: 40px;
  height: 40px;
}

.skills-bg {
  background: #d8e1fe;
  border-radius: 4px;
  color: #000;
}

.edit_profile_card_page {
  margin: auto;
  width: 100%;
  padding: 30px;
}

.profile_feild_cont {
  padding: 20px;
}
.profile_feild_border {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 10px;
}

.profile_feild_cont > Col {
  padding: 10px;
}
.editprofile_feild_cont {
  padding: 20px;
}
.editprofile_image {
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}
.editprofile_upper_feild {
  align-items: center;
  text-align: center;
  justify-content: center;
}

.profile_edit_model_main {
  min-width: 900px;
}

.profile_edit_model_header {
  height: 50px;
}
.profile_edit_model_header_text {
  margin-top: 10px !important;
  margin-left: 20px;
}

.company_edit_heading {
  color: rgb(0, 87, 248);
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .profile_edit_model_main {
    min-width: 700px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .profile_edit_model_main {
    min-width: 500px;
  }
}

.perks_selection {
  border: 0.5px solid #919191;
  border-radius: 8px;
  width: auto;
  /* padding: 10px; */
  cursor: pointer;
  padding: 10px;
  height: 100px;
  background-color: transparent;
  display: grid;
  width: 100%;
  place-items: center;
  -webkit-user-select: none;
          user-select: none;
}

.perks_selection:hover {
  border: 0.5px solid #008000;
}


.perks_selection>p {
  margin: 0px;
}

.selected_perk {
  position: relative;
  border: 1px solid #008000;
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.1);
}

.selected_perk::after {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #008000;
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2713";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  color: white;
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 1rem;
}

.cluture_image {
  border-radius: 10px;
  display: grid;
  height: 200px;
  place-items: center;
  /* max-width: ; */
  border: 1px solid #919191;
}

.row_sm {
  display: flex;
  width: 80%;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.row_sm>img {
  width: 100px;
  height: 100px;
}

.small_gray {
  color: gray;
  font-size: 10px !important;
}

.flex_center {
  display: flex;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}
.posts_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small_img {
  width: 40px;
  object-fit: contain;
  height: 40px;
}

.multi_flex {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.filter_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

/* .long_filter_data:hover {
    height: 100px;
} */

/* .height_0px {
    height: 0px;
}

.height_auto {
    height: auto;
} */
.mail-list a.active_data {
  background-color: black !important;
  color: white !important;
  border-radius: 4px;
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1);
  /* opacity: 0.9; */
}

.login_parent {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.login_img_section {
  flex: 0.3 1;
  display: flex;
  background-color: var(--sidebar--color);
  flex-direction: column;
  grid-gap: 100px;
  gap: 100px;
  align-items: center;
  padding: 25px;
  justify-content: center;
}

.card_image {
  flex: 0.7 1;
  display: grid !important;
  place-items: center !important;
  margin: 0px !important;
}

.main_content_login {
  width: 60%;
  position: relative;
  margin-bottom: 70px;
  /* height: 390px; */
}


.head_login {
  margin-bottom: 100px;
  text-align: center;
  font-size: 26px;
  letter-spacing: 10px;
  font-weight: bolder !important;
}

.welcome_text {
  margin-bottom: 40px;
  text-align: center;
  font-size: 26px;
  color: gray;
  letter-spacing: 1px;
  font-weight: bolder !important;
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
  top: 10px;
  right: 10px;
}

.login_inputs {
  border-radius: 10px !important;
  /* border: 1px solid rgb(143, 143, 143) !important; */
}

.login_form>* {
  margin-bottom: 20px;
}


@media(max-width:800px) {
  .login_parent>img {
    display: none;
  }

  .card_image {
    flex: 1 1;
  }
}

@media(max-width:1000px) {
  .main_content_login {
    width: 90% !important;
  }
}
.phone_no {
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.country_code {
  margin-right: 5px;
}

.PhoneInputCountryIcon {
  border-radius: 4px;
}

.PhoneInputCountryIconImg {
  border-radius: 4px;
}

.PhoneInputCountryIcon--border {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.otp_parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.input_phone {
  border: 1px solid var(--input-border);
  border-radius: 8px;
  padding: 6px;
  margin-top: 4px;
}

/* div .input_phone>input:focus {
  border: 0.5px solid var(--sidebar--color);
} */

.input_phone>input {
  border: none;
  font-family: var(--font) !important;
  font-size: var(--font-size--inputs) !important;
  padding: 6px !important;
}

.password_signup {
  width: 100%;
  display: flex;

  flex: 1 1;
  /* border: 1px solid var(--input-border); */
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.password_signup>div {
  width: 97%;
}

.password_signup>i {
  margin-top: 28px;
}

.password_signup_2 {
  width: 100%;
  display: flex;
  flex: 1 1;
  /* border: 1px solid var(--input-border); */
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.password_signup_2>div {
  width: 97%;
}

.password_signup_2>i {
  margin-top: 31px;
  margin-right: 4px;
}

.enter_otp {
  font-weight: bolder;
}

.otp_info {
  /* margin-top: 20px; */
  display: flex;
  text-align: center;
  color: gray;
  justify-content: center;
}

.otp_imp {
  /* margin-top: 20px; */
  display: flex;
  text-align: center;
  font-weight: bolder;
  color: var(--sidebar--color);
  justify-content: center;
}

.otp_columns {
  /* margin-top: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.modal_sub {
  width: 500px !important;
  border-radius: 40px !important;
}

.otp_input_items {
  border: none;
  border-bottom: 3px solid var(--input-border);
  color: black;
  text-align: center !important;
  font-size: 20px;
  display: grid;
  /* box-shadow: 0px 5px 1px #e6e3f4; */
  place-items: center;
  border-radius: 0px;
  -webkit-appearance: textfield;
          appearance: textfield;
  color: var(--theme-color--light);
  font-weight: bolder;
  outline: none;
  width: 45px !important;
  margin-right: 10px;
  height: 45px !important;
  background-color: none;
}

.otp_input_items:not(:placeholder-shown) {
  border-bottom: 3px solid var(--theme-color--light) !important;
}

.otp_input>span {
  display: none;
}

.otp_input_items_focus {
  border: none;
  border-bottom: 3px solid var(--theme-color--light) !important;
}

.otp_input_items_error {
  border: 3px solid red !important;
}

.otp_input>input::-webkit-outer-spin-button,
.otp_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.help_otp {
  display: flex;
  /* margin-top: 30px; */
  flex-direction: column;
  align-items: center;
}

.otp_verify {
  width: 50%;
  margin-top: 10px;
}

/* signup  */

.signup-name-cont {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 15px;
  gap: 15px;
}

#signup-form-card {
  border-radius: 5px;
}

/* package modification  */

label {
  margin-bottom: 10px !important;
}

.error_phone {
  border: 1px solid #D60D0D;
}
.select_for_job_type {
  border: 1px solid var(--theme-color--light);
  background-color: transparent;
  color: var(--theme-color--light);
  width: 90%;
  border-radius: 5px;
  padding: 10px;
}

.select_for_job_type:hover,
.selected_for_job_type {
  background-color: var(--theme-color--light);
  color: white;
}

.job_types_selection {
  border: 1px solid #919191;
  border-radius: 3px;
  width: 90%;
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  display: grid;
  min-height: 300px;
  /* width: 100%; */
  place-items: center;

  -webkit-user-select: none;

          user-select: none;
}

.job_types_selection:hover {
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.07);
  border: 0.5px solid var(--theme-color--light);
}

.selected_job_type {
  position: relative;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  color: var(--theme-color--light) !important;
  border: 0.5px solid var(--theme-color--light);
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.07);
}

.info_job_type {
  color: #3483eb;
  font-size: 11px;
  background-color: transparent;
  outline: none;
  border: none;
}
.job_hours {
  border: 1px solid #919191;
  border-radius: 3px;
  width: auto;
  cursor: pointer;
  height: auto;
  padding: 10px;
  height: 100px;
  background-color: transparent;
  display: grid;
  width: 70%;
  place-items: center;

  -webkit-user-select: none;

          user-select: none;
}

.job_hours:hover {
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--theme-color--light);
}

.selected_hours::after {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--theme-color--light);
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2713";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  color: white;
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 1rem;
}

.selected_hours {
  position: relative;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  border: 1px solid var(--theme-color--light);
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.07);
}

.info_job_type {
  color: #3483eb;
  font-size: 11px;
  background-color: transparent;
  outline: none;
  border: none;
}

.work_modes_selection {
  border: 1px solid #919191;
  border-radius: 3px;
  width: auto;
  /* padding: 10px; */
  cursor: pointer;
  padding: 10px;
  height: 80px;
  min-height: 200px;
  background-color: transparent;
  display: grid;
  width: 90%;
  object-fit: contain;
  place-items: center;

  -webkit-user-select: none;

          user-select: none;
}

.work_modes_selection>label {
  font-weight: bolder;
}

.work_modes_selection>img {
  width: 200px !important;
}

.work_modes_selection:hover {
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--theme-color--light);
}

.selected_work_mode>label {
  color: var(--theme-color--light);
}

.selected_work_mode::after {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--theme-color--light);
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2713";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  color: white;
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 1rem;
}

.selected_work_mode {
  position: relative;
  border: 1px solid var(--theme-color--light);
  box-shadow: 0px 0px 30px -1px rgba(0, 0, 0, 0.1);
}
#job-title-main {
  padding-top: 50px;
  padding-bottom: 50px;
  /* border: 1px solid red; */
}

#job-location-selection-cont {
  /* border: 1px solid rgb(163, 217, 136); */
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  width: 70%;
  margin-top: 10px;
  /* margin: auto; */
}

#job-location-boxes:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#job-location-boxes {
  border: 1px solid blue;
}

/* Package Css */
.css-1ms1y94-JoyRadioGroup-root .JoyRadio-radio > svg {
  z-index: 10 !important;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--joy-palette-common-white);
  border-radius: 50%;
  background: white !important;
}

#job-function-main-cont {
  margin-top: 30px;
  margin-bottom: 0px;
}
#job-close-model-input-cont {
  /* border: 1px solid red; */
  padding: 20px;
}

#job-type-main-cont {
  margin-top: 30px;
  margin-bottom: 0px;
  /* border: 1px solid red; */
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  margin: 0;
  padding: 20px !important;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

#job-attachmements-cont {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  gap: 40px;
  margin-top: 50px;
}

#job-application-deadline {
  margin-top: 15px;
  height: 38px;
  /* border: 1px solid red; */
}

#job-desc-file-uploader {
  width: 100% !important;
}

.lkjDVC {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 80px !important;
  border: dashed 1px #b0b0b0 !important;
  padding: 8px 16px 8px 8px;
  border-radius: 1px !important;
  cursor: pointer;
  flex-grow: 0;
}

#job-pre-header-cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#job-pre-header-cont > div:nth-child(3) {
  justify-content: flex-end;
  display: flex;
}

#primium-right {
  height: 30px;
  width: 35px;
  background: #3d82f5;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

#premium {
  /*   	border: 1px solid red; */
  height: 30px;
  width: 90px;
  background: #3d82f5;
  text-align: center;
  padding-top: 7px;
  border-left: 0.1px solid white;
}

#premium > h6 {
  color: white !important;
}

#primeum-right {
  width: 30px;
  height: 30px;
  /*   border: 1px solid red; */
}

#primeum-right > div:nth-child(1) {
  border-top: 0px solid transparent;
  border-bottom: 15px solid transparent;
  margin-left: -0.5px;
  border-left: 20px solid #3d82f5;
}

#primeum-right > div:nth-child(2) {
  /*   border: 1px solid green;
    height: 20px;
    border-top-right-radius : 200px; */
  border-top: 15px solid transparent;
  border-bottom: 0px solid transparent;
  margin-left: -0.5px;

  border-left: 20px solid #3d82f5;
}

#primium-icon {
  color: rgb(255, 218, 11) !important;
  font-size: 15px;
  padding: 7px;
}

#hiring-day-cont {
  /* border: 1px solid red; */
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#hiring-day-cont > div:nth-child(1) {
  /* border: 1px solid red; */

  padding: 10px;
}

#hiring-day-cont > div:nth-child(2) > div {
  display: flex;
  margin-top: 10px;
}

#hiring-day-cont > div:nth-child(2) > div > div:nth-child(1) {
  color: #3d82f5;
  padding-left: 10px;
  padding-right: 10px;
}

#hiring-day-cont > div:nth-child(2) > div > div:nth-child(2) > p > span {
  font-weight: bolder;
  font-size: 14px;
}

.anticon svg {
  display: inline-block;
  margin-top: -5px !important;
}

#skills-selected-tags {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
  color: white !important;
  border-radius: 20px !important;
  background: #1890ff !important;
}

#skill-main-cont {
  padding-top: 20px;
  margin-top: 10px;
}
.skill-models-header {
  height: 0px !important;
  border-bottom: 0px !important;
}

.skill-model-select-cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  gap: 8px;
  margin-top: 50px !important;
  margin-bottom: 20px !important;
}

.skill-model-select {
  border-radius: 0px !important;
}

.skill-model-btn {
  margin: auto !important;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 3px !important;
}
.skill-models-footer {
  border-top: 0px !important;
}

#basic-multi-select-box > div {
  /* border: 1px solid red; */
  max-height: 150px !important;
  /* position: relative; */
}
#basic-multi-select-box > div > div {
  max-height: 150px !important;
}

.job-edit-submit-btn {
  border: none;
  outline: none;
  background: white;
  border: 1px solid var(--theme-color--light);
  color: var(--theme-color--light);
  border-radius: 10px;
  width: 50%;
  font-size: 15px !important;
  padding: 10px !important;
  margin: 0% 25% 0% 25% !important;
}

.job-edit-submit-btn:hover {
  color: white;
  background-color: var(--theme-color--light);
}

.job_header_partner {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  padding: 20px;
  grid-gap: 10px;
  gap: 10px;
  /* justify-content: center; */
}

.job_header > h3 {
  font-weight: bolder;
}

.card_of_job_filter {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
}

.company_name_list {
  padding-left: 10px;
  color: black !important;
  text-decoration: none !important;
}

.screened-applicant-candidate-list {
  width: 70%;
  margin: auto;
  text-align: center;
}

.referal_partner_table {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.referal_partner_table > div:nth-child(1) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.referal_partner_table > div:nth-child(2) {
  text-align: center;
  padding-top: 10px;
}
.referal_partner_table > div:nth-child(1) > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.leadership-bg {
  background-color: #ebf2f6;
  border-radius: 5px;
  width: 80%;
  margin: auto;
}

.leadership-text {
  color: #08325b;
}

.leadership-desc {
  color: #101010;
  font-size: 14px;
}

.referEarnCard-bg {
  background-color: #2eae3e;
  border-radius: 12px;
}

.refereearn-text {
  color: #fff;
}

.refereearn-desc {
  color: #fff;
}

.question {
  font-size: 14px;
  font-weight: 500;
}

.leaderboard_main_cont {
  margin-top: 80px;
  padding: 10px;
  margin-bottom: 20px;
}

.leaderboard_mamber_row_cont {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.leaderboard_mamber_row_cont > div:nth-child(1) {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.leaderboard_mamber_row_cont > div:nth-child(2) {
  padding-left: 10px;
}

.leaderboard_mamber_row_cont > div:nth-child(1) > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.laeadership_table_cont {
  width: 80%;
  margin: auto;
}

.leadership_self_details_cont {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  /* text-align: center; */
  justify-content: center;
  align-items: center;
  margin-top: 30px !important;
  border-radius: 8px;
  padding: 10px;
  background: #cde6f6;
}

@media only screen and (min-width: 350px) and (max-width: 700px) {
  .leadership_self_details_cont {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    gap: 30px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 349px) {
  .leadership_self_details_cont {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    gap: 30px;
  }
}

.leadership_self_details_cont > div {
  /* border: 1px solid red; */
}
.leadership_self_ranks_image_cont > div {
  border: 3px solid rgb(2, 157, 204);
  border-radius: 20px;
  width: 100px;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.leadership_self_ranks_image {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.leadership_self_rank_details > h5 {
  font-weight: bold;
}
.leadership_self_rank_details > p {
  font-weight: 200;
  padding-top: 5px;
}

.myearning_main_cont {
  /* margin-top: 80px; */
  padding: 15px;
}

.col_icon {
  display: flex;

  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}

.no_data_show {
  height: 60vh;

  width: 100vw;

  display: grid;

  place-items: center;
}

.no_data_show > div {
  display: flex;

  flex-direction: column;

  grid-gap: 10px;

  gap: 10px;

  align-items: center;
}

.single-image-feature {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.sidebar-shadow {
  border: 1px solid rgba(6, 18, 36, 0.1);
  padding: 29px 33px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 9px 26px 0px rgb(31 31 51 / 6%);
  margin-bottom: 40px;
}

.sidebar-shadow .sidebar-heading {
  display: inline-block;
  width: 100%;
}

.text-description {
  font-size: 16px;
  color: #37404e;
  line-height: 24px;
  display: inline-block;
  width: 100%;
}

.sidebar-list-job {
  border-top: 1px solid rgba(6, 18, 36, 0.1);
  display: inline-block;
  width: 100%;
  padding: 30px 0px 0px 0px;
  margin: 30px 0px 0px 0px;
}

.sidebar-team-member {
  border-top: 1px solid rgba(6, 18, 36, 0.1);
  display: inline-block;
  width: 100%;
  padding: 30px 0px 0px 0px;
  margin: 30px 0px 0px 0px;
}

.pt-40 {
  padding-top: 40px !important;
}

.small-heading {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.twm-job-hilites {
  padding-bottom: 10px;
  font-weight: 500;
}

.twm-job-hilites li {
  display: block;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 8px 8px 0px;
  position: relative;
  color: #17171d;
  font-size: 16px;
  margin-bottom: 10px;
}

.modal-custom-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgb(56 152 226 / 30%);
  padding: 30px;
  /* margin-top: 90px; */
}

.twm-title {
  font-size: 14px;
  font-weight: 500;
  color: #17171d;
}

.twm-s-info-discription {
  font-size: 14px;
  color: #17171d;
  font-weight: 600;
}

.skills {
  background-color: #1967d236;
  color: #1967d2;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 15px;
}

.skills:hover {
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 15px;
}

.user-profile {
  height: 30px;
  width: 30px;
  border-radius: 20px;
}

.job_card {
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
  padding: 20px;
  padding-bottom: 30px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: 10px;
  /* min-height: 100%; */
}

.job-edit-icon {
  background-color: var(--theme-color--light);
  padding: 10px;
  position: absolute;
  color: white;
  border-radius: 50%;
  top: -10px;
  right: -10px;
}

.image-box {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.footer_job_list {
  /* margin-top: 20px; */
  /* position: absolute; */
  /* bottom: 10px; */
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  /* right: 20px; */
  /* left: 20px; */
}

.profile_alternative {
  font-weight: bolder;
  display: grid;
  place-items: center;
  width: 50px;
  /* background-color: rgb(255, 216, 124); */
  /* color: rgb(200, 140, 0); */
  text-transform: capitalize;
  height: 50px;
  border-radius: 50%;
}

.header_profile_alternative {
  font-weight: bolder;
  display: grid;
  place-items: center;
  width: 30px;
  /* background-color: rgb(255, 216, 124); */
  /* color: rgb(200, 140, 0); */
  text-transform: capitalize;
  height: 30px;
  border-radius: 50%;
}

.job_card:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: var(--global-shadow);
}

.job_details {
  display: flex;
  flex-direction: column;
}

.job_details_header {
  border-radius: 10px;

  font-weight: bolder;
  padding: 3px 7px;
  border: 1px solid;
  display: grid;
  /* margin-bottom: 8px; */
  font-size: 10px;
  place-items: center;
}

.header_3 {
  border-color: transparent;
  color: #057b7d;

  font-weight: bolder;
  font-size: 10px;
}

.header_1 {
  border-color: rgb(221, 239, 255);
  color: var(--theme-color--light);
}

.header_2 {
  color: #c8382d;
  border-color: #f4d7d5;
}

.header_4 {
  border-color: #dbdbde;
  color: #494b59;
}

.header_5 {
  border-color: #dbddeb;
  color: #49569c;
}

.job-title {

  font-weight: bolder;
  color: var(--theme-color--light) !important;
}

.job-title>a {
  color: var(--theme-color--light) !important;
}

.job_details_desc {
  color: gray;

  font-weight: bolder;
  font-size: 10px;
}

.key_skills {
  background-color: aliceblue;
  border-radius: 4px;
  color: var(--theme-color--light);
  padding: 5px;
}

.location {
  color: gray;
  font-size: 10px;
}

.job_description {
  max-height: 100px;
  height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(164, 164, 164);
  word-wrap: break-word;
}

.currency-text,
.currency-time {

  font-weight: bolder;
}

.delete_btn_job {
  color: red;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  cursor: pointer;
  align-items: center;
}

.approve_btn_job {
  color: green;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  cursor: pointer;
  align-items: center;
}
.sidebar {
  background-color: var(--sidebar--color);
  width: 70px;
  z-index: 99999;
  top: 0px;
  left: 0px;
  display: flex;
  /* overflow-x: visible; */
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  /* overflow-y: scroll; */
  align-items: center;
  position: fixed;
  min-height: 100vh;
}

.overXhide{
  overflow-x: hidden;
}


.left_main_content {
  /* position: fixed; */
  top: 0px;
  background-color: white !important;
  /* overflow: scroll; */
  right: 0px;
  bottom: 0px;
  /* min-height: 100vh; */
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.text_sidebar {
  font-size: 13px;
  white-space: nowrap;
  width: 100%;
  color: white !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p_ab {
  position: absolute;
}

.p_fix {
  position: fixed;
}

/* 0f0d0f */
/* #021a39 */
.width_f {
  width: 208px !important;
  box-shadow: 15px 0px 20px 1px rgba(0, 0, 0, 0.1);
}

.work_svg {
  background-image: url(/static/media/workmode.a49f97df.svg);
}

.skill_svg {
  background-image: url(/static/media/skills.e80d4091.svg);
}

.fn_svg {
  background-image: url(/static/media/functions.4d3a58d9.svg);
}

.buisness_svg {
  background-image: url(/static/media/buisness.a375355d.svg);
}

.size_color {
  width: 20px;
  height: 20px;
  background-size: contain;
  color: white;
}

.grad_svg {
  background-image: url(/static/media/icon_white.1d34adef.svg);
}

.div_big {
  position: absolute;
  top: 60px;
  right: -20px;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 18px;
  z-index: 99;
  background-color: rgb(233, 233, 233);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@-webkit-keyframes mover {
  0% {
    right: -18px;
  }

  100% {
    right: -30px;
    padding: 4px;
  }
}

@keyframes mover {
  0% {
    right: -18px;
  }

  100% {
    right: -30px;
    padding: 4px;
  }
}

.div_big>i {
  color: black;
  margin-top: 0px;
  height: 21px;
}

.list_icons:last-child {
  margin-bottom: 80px;
}

.sidebar_user_details_container {
  background-color: var(--sidebar--color);
  padding: 5px;
  margin: 7px 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.logo_sidebar {
  position: fixed;
  padding-top: 15px;
  /* padding-left: 15px; */
  padding-bottom: 15px;
  z-index: 1;
}

.sidebar_collapse_handler_container {
  position: relative;
  width: 100%;
}

.sidebar_collapse_handler {
  /* position: absolute; */
  /* top: 10px; */
  border-radius: 10%;

  display: grid;
  padding-top: 3px;
  color: white;
  margin-right: 5px;
  background-color: var(--sidebar--color--opacited);
  place-items: center;
  cursor: pointer;
  padding: 6px;
  font-size: 18px;
  z-index: 99;

}

.sidebar_user_details {

  /* background-color: var(--sidebar--color); */
  position: fixed;
  bottom: -16px;
  left: 0px;
  /* right: 0px; */
  padding: 10px 5px;
  color: white;

}

.w_img_f {
  width: 208px;
  display: grid;
  place-content: center;
  background-color: var(--sidebar--color);
}

.w_img_m {
  width: 70px;
  background-color: var(--sidebar--color);
}

.logo_img {
  width: 20px;
  height: 20px;
}

.logo_full {
  width: 100px;
  margin-left: -20px;
  object-fit: contain;
}

.w_full {
  width: 90%;
}

.sidebar_list {
  overflow-y: scroll;
  height: calc(100vh - 30px);
  margin-top: 80px;
  list-style: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.rot_full {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rot_full2 {
  -webkit-transform:rotate(180deg);
          transform:rotate(180deg);
}

.sidebar_list::-webkit-scrollbar {
  display: none;
}

.list_icons,
.arrow_icon {
  display: flex;
  color: white;
  text-decoration: none;
  text-transform: capitalize;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.list_icons>i {
  color: white;
  font-size: 20px;
  /* font-weight: 100; */
}

.list_icons::after {
  top: 50%;
  left: -14px;
  content: "";
  height: 0%;
  /* width: 4px; */
  overflow: auto;
  position: absolute;
  transition: 0.2s ease-in-out;
  /* height: 0%; */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.list_icons::before,
.arrow_icon::before {
  content: "";
  position: absolute;
  top: 0px;
  right: -25px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  color: white;
  /* height: 40px; */
  width: 0;
  background-color: #021a39;
}

.drop_cont {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
  /* z-index: -1 !important; */
  top: -20px;
  height: 0px;
}

.up_s {
  overflow: hidden;
}

.down_s {
  height: auto !important;
  top: 10px;
}

/* .list_icons:hover::before,
.arrow_icon:hover::before {
  content: attr(data-label);
  z-index: 999999;
  right: -170px;
  width: 150px;
  padding: 10px;
  text-align: center;
} */

.arrow_icon>i {
  color: white;
  font-size: 16px !important;
}

.list_icons:hover:after,
.list_click:after {
  top: 0px;
  height: 95%;
  background-color: rgb(193, 193, 193);
}

.item_exp {
  padding-left: 30px !important;
}

.sidebar_list>li>span,
.list_icons>li>span {
  color: white;
  text-transform: capitalize;
}

.sidebar_list>li,
.list_icons {
  padding: 10px;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
}

.sidebar_list>li:hover,
.list_icons:hover {
  background-color: rgba(193, 193, 193, 0.3);
}

.list_click {
  background-color: rgba(193, 193, 193, 0.3);
}

#sidebar_dropdowns:last-child {
  margin-bottom: 10px;
}
