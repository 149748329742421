.no_height {
  height: 0px;
  /* display: none; */
}

.long_filter {
  /* background-color: white; */
  display: flex;
  height: 100px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  column-gap: 20px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.form_company > .row {
  padding: 5px 0px;
}

/* .long_filter {
    border-radius: 10px;
    cursor: pointer;
    width: 50px;
    padding: 4px 8px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
}

.long_filter>span {
    display: flex;
    gap: 4px;
    align-items: center;
}

.long_filter:hover {
    background-color: #f4f4f5;

}

.clicked_filter {
    background-color: #f4f4f5 !important;
}

.long_filter_data {
    transform: translateY(-100%);
    z-index: -999 !important;
    overflow: scroll;
    transition: 0.4s ease-in-out;
    padding-right: 20px;
    height: 10px;
} */

.long_items {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
}

.filter_items {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
}

.filter__container {
  background-color: white;
  margin-bottom: 10px;
  position: relative;
  border-radius: 10px;
}

.translate_100 {
  position: absolute;
  transform: translateY(0%);
}

.translate_0 {
  position: absolute;
  transform: translateY(100%);
}

.table_row {
  background-color: white;
  margin: 10px 0px;
  border-radius: 10px;
}

.table_item {
  padding: 10px !important;
  /* display: grid; */
  /* place-items: center; */
}
