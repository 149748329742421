@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

:root {
  --global-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --global-radius: 10px;
  --global-border: #ebedf2;
  --input-border: #c4c4c4;
  --background-color: #081753;
  --dark--color--sidebar: #323747;
  --theme-color: #081753;
  --theme-color--light: #081753;
  --sidebar--color: #081753;
  --sidebar--color--opacited: #15235C;
  --otp-background-color: #f6f5fa;
  --font-size--inputs: 1rem;
  --font: 'Inter', sans-serif;

}

a {
  text-decoration: none !important;
}

.board_access_indicator {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  /* background-color: var(--sidebar--color); */
}

.header_sticky {
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: white;
  width:101%;
}

.link_navigation {
  color: black;
  border-bottom: 1px solid transparent;
  /* text-decoration: none; */
}

.link_navigation:hover {
  border-bottom: 1px solid black;
  color: black;

}

html>*,
.font_family_strict,
.font_family_strict>* {
  font-family: var(--font) !important;
}

.icon_clickable {
  cursor: pointer;
}

.overflow_y_scoll {
  height: 600px;
  overflow-y: scroll;
}

.pat-20 {
  padding-top: 50px;
}

.info_txt {
  margin-top: 10px;
  color: rgb(153, 152, 152);
}

/* .MuiSwitch-thumb {
  background-color: white !important;
} */

/* .MuiSwitch-track {
  background-color: var(--theme-color--light) !important;
} */
.max_img_container {
  max-width: 150px;
}

.lg_img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.bottom_div_margin_top>div {
  margin-top: 20px;
}

.theme_btn {
  background: var(--theme-color--light) !important;
  color: white !important;
}

/* best shadow -10px -15px 90px 0 rgba(0, 0, 0, 0.1) !important */
.ant-tooltip-arrow {
  display: none;
}

.tooltip_card {
  padding-bottom: 0px !important;
}

.tool_green>.ant-tooltip-content>.ant-tooltip-inner {
  color: #389e0d !important;
}

.tool_red>.ant-tooltip-content>.ant-tooltip-inner {
  color: #cf1322 !important;
}

.tooltip_card>.ant-tooltip-content>.ant-tooltip-arrow {
  color: white !important;
  display: none !important;
  border-radius: 6px !important;
}

.root {
  font-family: "Manrope", sans-serif !important;
}

body {
  font-family: "Inter", sans-serif !important;
  /* min-height: 140vh; */
}

.full_card_body {
  min-height: 100vh;
  display: grid;
  place-items: center;
}

/* 
#editor-js {
  width: 100% !important;
} */

.link_full {
  width: 100%;
  text-decoration: none;
  display: flex;
  padding: 10px;
  color: black;
  align-items: center;
}

.link_cont {
  padding: 0px !important;
}

.login_inpt {
  outline: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(143, 143, 143) !important;

  width: 100%;
}

/* 
@media(prefers-reduced-motion:no-preference) {
  .login_inpt:focus-visible {
    transition: outline-offset 2s ease-out;
  }

  .login_inpt:not(:active):focus-visible {
    transition-duration: .25ms;
  }
}

.login_inpt:not(:active):focus-visible {
  outline-offset: 0.15rem;
} */

.login_inpt:focus {
  outline: 1px solid var(--theme-color--light);
}

.signup-logo {
  font-size: 30px;
  letter-spacing: 5px;
}

.signup-register-text {
  margin-top: 20px;
  margin-bottom: 10px;
}

.signup_password_cont {
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
}

.signup_password_cont>div:nth-child(1) {
  /* 
  border: 1px solid red; */

  width: 95%;
}

.signup_password_cont>div:nth-child(2) {
  align-items: center;
  text-align: center;
  border: 1px solid #d4d9df;
  width: 40px;
  height: 36.7px;
  margin-top: 29.5px;
  padding-top: 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.signup_password_field {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* .account-pages{
  fon
} */

.form_inpt {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cur_pt {
  cursor: pointer;
}

.password_inpt {
  display: flex;
  align-items: center;

  gap: 10px;
  border: 1px solid rgb(143, 143, 143);
  border-radius: 10px;
}

.border_theme {
  border: 1px solid var(--theme-color--light);
}

.password_inpt>input {
  outline: none;
  width: 90%;
  padding: 10px;
  border-radius: 10px;
  border: none;
}

.form_inpt>i {
  margin-top: 15px;
}

.form_inpt>div>input {
  border: none;
}

.cover_img_md {
  object-fit: cover;
  width: 50px;
  border-radius: 10px;
  height: 50px;
  padding: 2px;
  background-color: var(--theme-color--light);
}

.md_img {
  object-fit: contain;
  width: 100px;
  height: 100px;
}

.dis_n {
  display: none;
}

.no_margin {
  margin: 0px;
}

.eighty_vh_max_child {
  max-height: 80vh;
  overflow: hidden;
}

.small_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.min_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  /* padding-right: 5px; */
}


.child_col_m_2>.col-12 {
  margin-top: 30px;
}

/* .input_search {
  border: 1px solid hsl(0, 0%, 70%);
} */

.input_search_icon>.select2-selection>.css-yk16xz-control {
  /* border: none;
  outline: none; */
  padding-left: 12px;
}

.input_search_icon>div>.form-control {
  padding-left: 30px;
}

.input_search_icon:active::after,
.input_search_icon:visited::after {
  content: "";
}

.input_search_icon::after {
  position: absolute;
  top: 42px;
  transform: rotate(257deg);
  font-size: 30px !important;
  font-weight: 100 !important;
  left: 0px;
  padding-left: 10px;
  color: #556ee6;
  border-radius: 50%;
  padding-bottom: 4px;
  content: "\2315";
  display: grid;
  padding: 2px;
  padding-top: 0px;
  /* color: white; */
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  font-weight: bolder;
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 1rem;
}

.child_col_m_2>.col-12>label {
  margin-bottom: 5px;
}

.uppercase {
  text-transform: uppercase;
}

.small_img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.rounded_small_button {
  /* padding: 5px; */
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 50px;
  display: grid;
  place-items: center;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
}

.rounded_small_button>p {
  margin: 0px !important;
}

.rounded_full {
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
}

.flex_tags {
  display: flex;
  align-items: center;
  border: 1px solid #cacaca;
  border-radius: 10px;
  gap: 4px;
}

.rounded_small_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
  /* object-fit: contain; */
}

.company_img {
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.company_img>img {
  min-width: 100%;
  min-height: 100%;
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
}

.child_col_mt>.col-4 {
  margin-top: 20px;
}

.large_img {
  max-width: 130px;
  border-radius: 10px;
  max-height: 130px;
  object-fit: contain;
}

.small {
  font-size: 10px;
}

.min-wi-8 {
  min-width: 100px;
}

.semi_bold {
  font-weight: 800 !important;
  font-size: 15px;
}

.margin_bottom_sm {
  margin-bottom: 10px;
}

.yellow {
  color: rgb(111, 111, 66);
}

.global_card {
  border-radius: var(--global-radius) !important;
  cursor: pointer;
  box-shadow: var(--global-shadow);
  transition: 0.5s ease-in-out;
}

.global_card:hover {
  transform: translateY(-4px);
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.14);
}

.global_gap {
  display: flex !important;
  flex-direction: column !important;
  gap: 30px !important;
}

.filter_cont::-webkit-scrollbar {
  display: none;
}

.scrollbar_none::-webkit-scrollbar {
  display: none;
}

@media (max-width: 500px) {
  .filter_cont::-webkit-scrollbar {
    /* width: 2px !important; */
    display: block;
    height: 2px !important;
  }

  .filter_cont::-webkit-scrollbar-track {
    background-color: var(--global-border);
    margin-left: 4px;
    border-radius: 10px;
  }

  .filter_cont::-webkit-scrollbar-thumb {
    border-radius: 10px;
    margin-top: 4px;
    /* width: 2px !important; */
    background-color: #bbbbbb;
  }
}


.global_scroll::-webkit-scrollbar {
  width: 8px;
}

.global_scroll::-webkit-scrollbar-track {
  background-color: var(--global-border);
  margin-top: 4px;
  border-radius: 10px;
}

.global_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin-top: 4px;
  background-color: #bbbbbb;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: var(--global-border);
  margin-top: 4px;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin-top: 4px;
  background-color: #bbbbbb;
}

.filter_cont {
  width: auto;
  padding-bottom: 5px;
  display: flex;
  /* background-color: white; */
  gap: 10px;
  overflow-x: scroll;
}

.filter_max_width {
  width: auto;
}

/* 
.filter_cont {
  overflow-y: hidden;
}
*/

.up-icon {
  transform: rotate(180deg);
}

.down-icon {
  transform: rotate(0deg);
}

.filter {
  position: relative;
  padding: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 999;
  display: grid;
  place-items: center;
  user-select: none;
  border: 1px solid var(--global-border);
}

.time-zones {
  position: relative;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  z-index: 999;
  display: grid;
  place-items: center;
  user-select: none;
  border: 1px solid var(--global-border);
}

.selected_time_zones {
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  width: auto;
  display: grid;
  place-items: center;
  padding: 7px 7px;
  background-color: var(--sidebar--color);
  color: white;
}

.selected_time_zones>span {
  display: flex;
  align-items: center;
}

.icon_filters>i {
  font-size: 18px !important;
  /* font-weight: bold; */
}

.filter>span {
  /* gap: 10px; */
  width: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.reddish>span {
  width: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.filter:hover {
  background-color: var(--global-border);
}

.input_cont {
  margin-left: 20px;
  display: flex;
  gap: 20px;
  transform: scale(1.4);
}

.filter_box {
  position: absolute;
  border-radius: 10px;
  box-shadow: 10px 10px 20px -1px rgba(0, 0, 0, 0.1),
    -10px -10px 20px -1px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 20px;
  width: 400px;
  background: white;
  height: 400px;
}

.click_btn_option {
  color: #5d78ff;
  background-color: #ebefff;
  padding: 10px !important;
  outline: none;
  border: none;
  min-width: 40px;
  display: gird;
  place-items: center;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}

.click_btn_option:focus {
  color: white;
  background-color: #5d78ff;
}

.click_btn_option:hover {
  background-color: #5d78ff;
  color: white;
}

@media (max-width: 400px) {
  .filter_box {
    max-width: 100vw !important;
  }
}

@media (max-width: 900px) {
  .filter_box {
    position: absolute;
    top: 150px;
    right: 20px;
  }
}

.share__job {
  position: absolute;
  left: -350px !important;
  border-radius: 10px;
  box-shadow: var(--global-shadow);
  z-index: 999;
  padding: 20px;
  width: 400px;
  background: white;
  /* height: 400px; */
}

.close_cont {
  position: relative;
}

.close_item {
  position: absolute;
  right: -10px;
  top: -11px;
  cursor: pointer !important;
  width: 20px;
  height: 20px;
  z-index: 9999;
  background-color: #babbba;
  color: white;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

/* 
.he::after {
  content: "";
  top: 0px;
  left: 0px;
  content: '\2606'
} */
.reddish {
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  display: grid;
  place-items: center;
  padding: 5px 0px;
  background-color: var(--dark--color--sidebar);
  color: white;
}

.reddish>span {
  /* gap: 10px; */
  width: 100px;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.react-kanban-board {
  min-height: 100%;
}

.react-kanban-board>div {
  min-height: 75vh;
}

.react-kanban-board::-webkit-scrollbar {
  width: 3px;
}

.react-kanban-board::-webkit-scrollbar-track {
  background-color: var(--global-border);
  margin-top: 4px;
  border-radius: 4px;
}

.react-kanban-board::-webkit-scrollbar-thumb {
  border-radius: 4px;
  margin-top: 4px;
  background-color: #bbbbbb;
}

.cover_img_cont {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.company_details {
  display: flex;
  flex-direction: row;
}

.company_details>div:nth-child(2) {
  padding-top: 40px;
  padding-left: 20px;
  font-weight: bold;
}

.company_details>div:nth-child(2)>h4 {
  font-weight: bold;
}

.personal_details {
  border: 1px solid rgb(221, 220, 220);
  margin-right: 10px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px;
}

.company_job_details {
  border: 1px solid rgb(221, 220, 220);
  border-radius: 5px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.analytics_cont {
  border: 1px solid rgb(221, 220, 220);
  padding: 20px;
  border-radius: 5px;
}

.company-font-weight {
  font-weight: bolder;
}

.company-fonts {
  text-align: center;
}

.cover_left {
  margin-top: 100px !important;
}

.company-text {
  font-size: 20px;
}

.profile_img {
  border-radius: 10px;
  background-color: white;
  object-fit: cover;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.07);
}

.cover_img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  max-height: 150px;
  object-fit: cover;
}

.react-kanban-column {
  transition: 0.8s ease-in-out !important;
  border-radius: var(--global-radius) !important;
}

/* .react-kanban-column:hover {
  min-height: 400px !important;
  background-color: rgba(212, 239, 255, 0.5) !important;
} */

/* div[data-rbd-draggable-id="column-draggable-5"]:hover {
  background-color: rgba(255, 202, 202, 0.5) !important;
}

div[data-rbd-draggable-id="column-draggable-4"]:hover {
  background-color: rgba(202, 255, 234, 0.5) !important;
} */

/* .div{
   color: purple;
} */
.no_scroll::-webkit-scrollbar {
  display: none;
}

.green_text {
  color: rgb(62, 171, 72);
}

.green_light {
  color: rgb(10, 187, 135);
}

.gap_20px {
  display: flex;
  gap: 20px;
  align-items: center;
}

.linkedin_btn {
  background-color: rgb(0, 115, 177);
  cursor: pointer;
  outline: none;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 4px;
  display: grid;
  place-items: center;
}

.linkedin_btn>div {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.width_60 {
  max-width: 60%;
  word-break: break-all;
  word-wrap: break-word;
}

/* .padding_20px */
.small_p {
  color: gray;
  font-size: 10px;
  margin: 0px;
}

.padding_x_20px {
  padding: 10px 10px;
}

.row_margin_20>.row {
  margin-bottom: 20px;
}

.text_gray {
  color: gray;
}

.skill_bar_items {
  background-color: transparent;
  border: 1px solid #fafaff;
  color: white;
}

.edit_btn {
  border: none;
  outline: none;
  background-color: green;
  border-radius: 4px;
  color: white;
}

.remove_btn {
  color: black;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: transparent;
  border: none;
}

.plus_btn_end {
  position: absolute;
  top: 30px;
  right: 10px;
}

.back_icon_container {
  position: relative;
}

.back_icon {
  position: absolute;
  top: 10px;
  cursor: pointer;
  left: 10px;
}

.margin_0 {
  margin: 0px;
}

.custom_btn {
  border: 1px solid #74788d !important;
}

.custom_btn:hover {
  color: white !important;
  background-color: #74788d !important;
}

.custom_btn:focus {
  background-color: #74788d !important;
  color: white !important;
}

.rounded_btn_violet {
  border: 1px solid var(--background-color);
  background-color: transparent;
  outline: none;
  color: var(--background-color);
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  transition: 0.4s ease-in-out;
}

.text-white {
  color: white;
}

.clicked_item_create {
  color: white !important;
  background: #74788d !important;
}

.border_sm_round {
  border-radius: 10px !important;
}

.rounded_btn_violet:hover {
  background-color: var(--background-color);
  opacity: 0.9;
  color: white;
}

.rounded_btn_violet_click {
  background-color: var(--background-color) !important;
  color: white !important;
}

.rounded_click {
  border: 1px solid var(--background-color);
  /* background-color: transparent; */
  outline: none;
  /* color: var(--background-color); */
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  transition: 0.4s ease-in-out;
  background-color: var(--background-color) !important;
  color: white !important;
}

.bottom_click {
  background-color: white;
  box-shadow: 0px 10px 20px -1px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
  z-index: 999;
  position: relative;
}

.bottom_click::after {
  position: absolute;
  content: "";
  bottom: 1px;
  width: 0%;
  left: 50%;
  z-index: 970;
  transition: 0.4s ease-in-out;
  border-radius: 10px;
  height: 4px;
}

.bottom_click:hover::after {
  width: 98%;
  left: 0px;
  background-color: var(--theme-color);
}

.bottom_click:focus::after {
  width: 100%;
  left: 0px;
  background-color: var(--theme-color);
}

.theme_btn {
  outline: none;
  border: none;
  padding: 10px;
  background-color: var(--theme-color);
  border-radius: 10px;
  box-shadow: 0px 10px 20px -1px rgba(0, 0, 0, 0.1);
  color: white;
}

.blue_btn {
  background-color: var(--background-color) !important;
  color: white !important;
}

.filter_data {
  /* font-size: 15px !important; */
  text-transform: capitalize;
  margin: 0px;
}

.filter_data_box {
  /* font-size: 15px !important; */
  padding: 10px 0px;
  margin: 0px;
  text-transform: uppercase;
  margin: 0px;
}

.bg_white {
  background-color: white;
}

input[type="checkbox"] {
  cursor: pointer !important;
}

.skills>span {
  padding: 5px;
}

.h6info {
  margin-top: 5px !important;
  font-size: 12px !important;
}

.violet {
  color: var(--background-color);
  background-color: rgba(200, 126, 246, 0.1) !important;
}

iframe {
  pointer-events: none;
}

.job_title_input:focus {
  border: 1px solid black !important;
}

.flex_wrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

#education_qual {
  margin-bottom: 20px;
  outline: none !important;
}

#education_qual:focus {
  outline: none !important;
}

#education_qual>*:hover {
  outline: none !important;
  border: none !important;
}

.border_red {
  border: 1px solid red !important;
}

.text_sm_gray {
  color: gray;
  font-size: 10px;
}

.child_span_margin>span {
  margin-right: 10px;
  padding: 5px !important;
}

.location_table_cell {
  color: gray;
  text-transform: uppercase;
}

.commission_column {
  color: #aeb1b8;
}

.commission_column>span {
  padding: 10px;
  font-size: 12px !important;
}

.active_td>span {
  padding: 5px 10px !important;
  font-size: 11px !important;
  font-weight: 1000 !important;
  border-radius: 4px !important;
}

.h_or_p {
  margin: 0px !important;
}

.no_border_inpt {
  border: none !important;
}

.currency_inpt {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-left: 5px;
}

.PhoneInputInput {
  padding: 10px !important;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.view_more_btn_job {
  background-color: var(--theme-color--light);
  color: white;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 1000;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.cover_pages {
  padding-top: 68px;
}

.map_list {
  background-color: white;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  padding: 0px;

  border-bottom-right-radius: 10px;
}

.map_items {
  list-style-type: none;
  padding: 10px;
}

.map_items:hover {
  background-color: #e8e9eb7d;
}

.loader {
  position: relative;
}

.loader::after {
  content: "";
  background-color: var(--theme-color--light);
  position: absolute;
  width: 20px;
  border-radius: 50px;
  height: 20px;
  animation: 1s scaler infinite;
}

.loader::before {
  content: "";
  background-color: var(--theme-color--light);

  position: absolute;
  top: 5px;
  left: 5px;

  border-radius: 50px;
  width: 10px;
  height: 10px;
  animation: 1.8s secondary infinite;
}

@keyframes scaler {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1.9);
    opacity: 0;
  }
}

@keyframes secondary {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(2);
    opacity: 0;
  }
}

.PhoneInputCountry {
  margin: 4px 0px;
  padding-right: 5px;
  /* border-right: 1px solid var(--input-border); */
}

.disabled_btn {
  opacity: 0.4;
  background-color: var(--theme-color--light);
}

.no_content_full_screen {
  width: 100%;
  min-height: 80vh;
  display: grid;
  place-items: center;
}

.search_bar_fliter {
  border: 1px solid black !important;
  border-radius: 2px !important;
  color: black !important;
}

.skill_badge {
  border: 1px solid rgb(235, 237, 242);
  background: rgb(241, 242, 247);
  padding: 5px;
  border-radius: 5px;
}

.react-kanban-column>div:nth-child(2) {
  overflow-y: scroll !important;
  height: 80vh !important;
}

.remove_text {
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kanban_left_icon {
  font-weight: bold;

}

.w-seventy {
  width: "70%"
}

.header_jobs_sticky {
  position: sticky;

  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  top: 58px;
  background: white;
  z-index: 1000;
  padding: 10px 0px;
}

.nav_tabs_custom {
  height: 10px;
}

.job_user_card_information {
  cursor: pointer;
}

.job_user_card_information:hover {
  background: rgba(255, 255, 255, 0.5);
}

.rdw-editor-toolbar {
  border: '1px solid rgba(0,0,0,0.5) !important'
}

.rdw-editor-main {
  border: '1px solid rgba(0,0,0,0.5) !important'
}