.leadership-bg {
  background-color: #ebf2f6;
  border-radius: 5px;
  width: 80%;
  margin: auto;
}

.leadership-text {
  color: #08325b;
}

.leadership-desc {
  color: #101010;
  font-size: 14px;
}

.referEarnCard-bg {
  background-color: #2eae3e;
  border-radius: 12px;
}

.refereearn-text {
  color: #fff;
}

.refereearn-desc {
  color: #fff;
}

.question {
  font-size: 14px;
  font-weight: 500;
}

.leaderboard_main_cont {
  margin-top: 80px;
  padding: 10px;
  margin-bottom: 20px;
}

.leaderboard_mamber_row_cont {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.leaderboard_mamber_row_cont > div:nth-child(1) {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.leaderboard_mamber_row_cont > div:nth-child(2) {
  padding-left: 10px;
}

.leaderboard_mamber_row_cont > div:nth-child(1) > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.laeadership_table_cont {
  width: 80%;
  margin: auto;
}

.leadership_self_details_cont {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  /* text-align: center; */
  justify-content: center;
  align-items: center;
  margin-top: 30px !important;
  border-radius: 8px;
  padding: 10px;
  background: #cde6f6;
}

@media only screen and (min-width: 350px) and (max-width: 700px) {
  .leadership_self_details_cont {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 349px) {
  .leadership_self_details_cont {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

.leadership_self_details_cont > div {
  /* border: 1px solid red; */
}
.leadership_self_ranks_image_cont > div {
  border: 3px solid rgb(2, 157, 204);
  border-radius: 20px;
  width: 100px;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.leadership_self_ranks_image {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.leadership_self_rank_details > h5 {
  font-weight: bold;
}
.leadership_self_rank_details > p {
  font-weight: 200;
  padding-top: 5px;
}
