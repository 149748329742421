.job__addition {
  max-width: 100%;
  display: flex;
  margin-left: 10px;
  gap: 10px;
  /* min-height: 450px; */
  border-radius: 4px;
  flex-direction: column;
  height: 100%;
  padding: 10px 20px;

  background-color: #f8faff;
  box-shadow: var(--global-shadow);
  border-radius: var(--global-radius);
  padding-bottom: 20px !important;
}

.screened_application_topbar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .screened_application_topbar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .screened_application_topbar {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}

.job_head_sub {
  display: flex;
  align-items: center;
  font-size: 11px !important;
  justify-content: space-between;
}

.job_filter_text {
  display: block;
}

.height_full {
  position: relative;
  height: auto;
}

.job__addition>.down_icon {
  display: none;
}

.job_small_addition {
  overflow-y: scroll;
  max-width: 100%;
  display: flex;
  margin-left: 10px;
  gap: 10px;
  /* max-height: 90vh; */
  overflow: hidden;
  border-radius: 4px;
  flex-direction: column;
  padding: 10px 20px;

  background-color: #f8faff;
  box-shadow: var(--global-shadow);
  border-radius: var(--global-radius);
  padding-bottom: 20px !important;
}

.down_icon,
.down_icon_large {
  border: none;
  padding: 10px;
  display: grid;
  place-items: center;
  outline: none;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 50%;
}

.ant-btn {
  background-color: var(--background-color) !important;
  /* height: 80px !important; */
  color: white !important;
  border-radius: 4px !important;
}

.job_lists {
  display: flex;
  flex-direction: column;
}

.job_list_header {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--global-radius);
  box-shadow: var(--global-shadow);
  padding-left: 10px;
  height: 50px;
  font-weight: 900;

}

.job_list_header>h4 {
  margin: 0px;
}

.single_job {
  padding: 10px 20px;
  display: flex;
  /* width: 200px; */
  gap: 10px;
  background-color: white;
  border-radius: var(--global-radius);
  align-items: center;
  box-shadow: var(--global-shadow);
  min-height: 150px;
}

.card_full_width {
  width: 100%;
}

.single_job:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.comp_img {
  border-radius: 50%;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.comp_img_cont {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.job_head_main {
  display: flex;

  gap: 10px;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.job_head>.job_head_main>h4 {
  cursor: pointer;
  padding-top: 4px;
  font-weight: 700;
}

.job_head>.job_head_main>.anticon:hover {
  color: rgb(208, 143, 15);
}

.job_head>.job_head_main>h4:hover {
  color: var(--background-color);
}

.job_head_right {
  position: absolute;
  top: 0px;
  right: -10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-self: flex-end;
}

.ant-tooltip-inner {
  color: black !important;
}

.job_section {
  display: flex;
  justify-content: space-between;
  /* gap: 8px; */
  /* flex-direction: column; */
}

.job_section_desc {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* 
.job_section_desc:nth-child(2):after,
.job_section_desc:nth-child(3)::after {
  content: "";
  left: -10px;
  position: absolute;
  top: 5px;
  width: 1px;
  height: 20px;
  background-color: grey;
} */

.total_num {
  font-weight: 500;
  font-size: 14px;
}

.single_job_footer {
  display: flex;
  justify-content: flex-end;
}

.single_job_tag {
  cursor: pointer;
  border-radius: 4px;
}

.single_job_tag:hover {
  opacity: 0.9;
}

.cursor {
  cursor: pointer;
}

.border_bottom {
  border-bottom: 1px solid var(--global-border);
  padding-bottom: 10px;
  width: 100%;
}

.bg_global {
  color: var(--background-color) !important;
  background-color: #d0d8ff !important;
}

.bg_global:hover {
  background-color: var(--background-color);
  color: white;
}

.w_fourty {
  min-width: 40%;
}

.btn_container {
  display: flex;
  justify-content: space-between !important;
}

.job_list_wrap {
  width: 100%;
  /* position: relative; */
}

.add_new_jobs_btn {
  background-color: #5d78ff;
  color: white;
  padding: 10px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.job_filter_names {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 10px;
}

.job_filter_radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

.job_filter_radio>label {
  margin: 0px !important;
  font-weight: 400;
}

.job_filter_radio>input {
  /* display: flex; */
  /* align-items: flex-end; */
  /* margin: 0px !important; */
  margin-right: 10px;
}

.invite_profile_card {
  display: flex;
  justify-content: flex-start;
  gap: 20px !important;
  align-items: center;
}

.invite_profile_round {
  width: 80px;
  height: 80px;
  background-color: #fd397a1a;
  color: #fd397a;
  font-weight: bolder;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.invite_profile_round>span {
  transform: scale(1.4);
}

.invite_profile_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
}

.data_edit {
  border: none !important;
  margin-left: 4px;
}

.candidate_email {
  font-size: 14px;
  min-height: auto !important;
}

.filter_horizontal {
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-around;
}

#screened_application_sidebar {
  box-shadow: 0px 0px 5px rgb(56 152 226 / 30%);
}

.basic-4 {
  background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0) 1px no-repeat;
  transition: 0.3s, background-position 0s 0.3s;
}

.basic-4:hover {
  --d: 100%;
  --p: 100%;
}