.btn_4 {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

.option_effects {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* gap:10px; */
}

.option_action > div {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
