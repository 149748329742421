.cursor-pointer {
  cursor: pointer;
}

.job_head_right {
  position: absolute;
  top: 16px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-self: flex-end;
}

.card-grid-2 {
  border-radius: 10px;
  border: 0.88px solid rgba(6, 18, 36, 0.1);
  overflow: hidden;
  min-height: 213px;
  margin-bottom: 30px;
  position: relative;
  background: #ffffff;
}

.hover-up {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.card-grid-2:hover {
  box-shadow: 0px 9px 26px 0px rgb(31 31 51 / 6%) !important;
  border-color: #b4c0e0 !important;
}

.card-grid-2-img-small {
  background-color: #5aa6ff;
  height: 48px;
  width: 48px;
  border-radius: 12px;
}

.job-name {
  display: inline-block;
  color: #1c58f2;
  font-size: 14px;
  font-weight: 600;
}

.tags-names {
  border-radius: 10px;
}

.btn-grey-small {
  background-color: rgba(151, 119, 250, 0.12);
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 6px;
  color: #37404e;
}
