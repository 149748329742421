.editor_blog {
  width: 100%;
  height: 100%;
}

.blog_title {
  width: 100%;
  padding: 20px;
}

.editing_section {
  /* height: 100vh; */
  /* max-width: 70vh; */
  overflow: hidden;
  margin-top: 40px;
  background-color: white;
  padding-bottom: 100px;
  display: grid;
  place-items: center;
}

.editing_section>div {
  display: flex;
  /* max-width: 70vw; */
  flex-direction: column;
  padding-left: 20px;
  /* align-items: center; */
  gap: 20px;
}

.length_text {
  color: gray;
}

.title {
  display: flex;
  position: relative;

  align-items: center;
  min-height: 100px;
  height: auto;
  /* align-items:; */
}

.image_placer {
  /* max-height: 300px; */
  object-fit: contain;
}

/* .image_adder>img:nth-child(2) {
    display: none;
} */

.image_adder>img {
  max-width: 300px;
}

.no_image {
  visibility: hidden;
  height: 0px !important;
  overflow: hidden;
}

.add_icon {
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translate(50%, -50%);
  left: -10%;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  padding: 5px;
  place-items: center;
}


.title>textarea {
  margin-top: 50px;
  background-color: transparent;
  resize: none;
  outline: none;
  height: 100%;
  max-width: 600px;
  width: 100%;
  height: auto;
  /* overflow:inherit; */

  word-wrap: break-word;
  break-after: always;
  overflow-wrap: break-word;
  font-weight: bolder;
  font-size: 2.5rem;
  border: none;
}

.title>textarea::-webkit-scrollbar {
  display: none;
}

.ce-block__content {
  margin: 0px !important;
}

.title>textarea::placeholder {
  /* text-rendering: geometricPrecision; */
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 2.5rem;

}

.ce-paragraph::before {
  color: gray;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 3rem;

}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: text;
  display: block;
  color: gray;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 1rem;

  /* For Firefox */
}

.short_desc>textarea,
.short_desc>div[contenteditable="true"] {
  background-color: transparent;
  outline: none;
  height: 100%;
  resize: none;
  width: 100%;
  font-size: 1rem;
  max-width: 600px;
  border: none;
}

.short_desc>textarea::placeholder {
  /* text-rendering: geometricPrecision; */
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 4px;
  font-size: 1rem;

}

.form_submit {
  margin-top: 20px;
  background: none;
  align-self: flex-start;
  padding: 10px 30px;
  border: none;
  /* width: 350px; */
  /* font-size: ; */
  display: flex;
  justify-content: flex-start;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  cursor: pointer;
}

.form_submit:after,
.form_submit:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 2px solid #021a39;
  transition: transform 0.2s;
}

.form_submit:after {
  transform: translate(3px, 3px);
}

.form_submit:before {
  transform: translate(-3px, -3px);
}

.form_submit:hover:after,
.form_submit:hover:before {
  transform: translate(0);
}

.category_section>div:last-child {
  margin-right: 50px;
}

.category_container {
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  max-width: 800px;
  font-size: 12px;
}

.left_arrow {
  position: absolute;
  top: 0%;
  left: -5%;
  height: 100%;
  /* margin-right: 30px; */
  width: 20px;
  background-color: white;
  opacity: 1;
  /* opacity: 1; */
  box-shadow: 40px 0px 20px rgba(255, 255, 255, 1);
  /* padding-right: 20px; */
  /* left: 0px; */
}

.right_arrow {
  position: absolute;
  top: 0%;
  padding-left: 20px;
  background-color: white;
  box-shadow: -20px 0px 20px rgba(255, 255, 255, 1);
  right: 0px;
}

.left_arrow,
.right_arrow {
  display: grid;
  place-items: center;
  height: 100%;
  cursor: pointer;
}

.category_section {
  display: flex;
  position: relative;
  align-items: center;
  scroll-behavior: smooth;
  justify-content: flex-start;
  gap: 20px;
  /* max-width: 40vw; */
  overflow-x: scroll;
  padding-bottom: 10px;
}

.category_section>div {
  border: 1px solid #021a39;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
}

.category_section::-webkit-scrollbar {
  display: none;
}

.clicked_btns {
  background-color: #021a39;
  color: white;
}

#editor-js>a,
#editor-js>*>a,
.ce-block__content>*>a {
  color: black;
  font-weight: bolder;
}