.phone_no {
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.country_code {
  margin-right: 5px;
}

.PhoneInputCountryIcon {
  border-radius: 4px;
}

.PhoneInputCountryIconImg {
  border-radius: 4px;
}

.PhoneInputCountryIcon--border {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.otp_parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.input_phone {
  border: 1px solid var(--input-border);
  border-radius: 8px;
  padding: 6px;
  margin-top: 4px;
}

/* div .input_phone>input:focus {
  border: 0.5px solid var(--sidebar--color);
} */

.input_phone>input {
  border: none;
  font-family: var(--font) !important;
  font-size: var(--font-size--inputs) !important;
  padding: 6px !important;
}

.password_signup {
  width: 100%;
  display: flex;

  flex: 1;
  /* border: 1px solid var(--input-border); */
  align-items: center;
  gap: 5px;
}

.password_signup>div {
  width: 97%;
}

.password_signup>i {
  margin-top: 28px;
}

.password_signup_2 {
  width: 100%;
  display: flex;
  flex: 1;
  /* border: 1px solid var(--input-border); */
  align-items: center;
  gap: 5px;
}

.password_signup_2>div {
  width: 97%;
}

.password_signup_2>i {
  margin-top: 31px;
  margin-right: 4px;
}

.enter_otp {
  font-weight: bolder;
}

.otp_info {
  /* margin-top: 20px; */
  display: flex;
  text-align: center;
  color: gray;
  justify-content: center;
}

.otp_imp {
  /* margin-top: 20px; */
  display: flex;
  text-align: center;
  font-weight: bolder;
  color: var(--sidebar--color);
  justify-content: center;
}

.otp_columns {
  /* margin-top: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal_sub {
  width: 500px !important;
  border-radius: 40px !important;
}

.otp_input_items {
  border: none;
  border-bottom: 3px solid var(--input-border);
  color: black;
  text-align: center !important;
  font-size: 20px;
  display: grid;
  /* box-shadow: 0px 5px 1px #e6e3f4; */
  place-items: center;
  border-radius: 0px;
  appearance: textfield;
  color: var(--theme-color--light);
  font-weight: bolder;
  outline: none;
  width: 45px !important;
  margin-right: 10px;
  height: 45px !important;
  background-color: none;
}

.otp_input_items:not(:placeholder-shown) {
  border-bottom: 3px solid var(--theme-color--light) !important;
}

.otp_input>span {
  display: none;
}

.otp_input_items_focus {
  border: none;
  border-bottom: 3px solid var(--theme-color--light) !important;
}

.otp_input_items_error {
  border: 3px solid red !important;
}

.otp_input>input::-webkit-outer-spin-button,
.otp_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.help_otp {
  display: flex;
  /* margin-top: 30px; */
  flex-direction: column;
  align-items: center;
}

.otp_verify {
  width: 50%;
  margin-top: 10px;
}

/* signup  */

.signup-name-cont {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 15px;
}

#signup-form-card {
  border-radius: 5px;
}

/* package modification  */

label {
  margin-bottom: 10px !important;
}

.error_phone {
  border: 1px solid #D60D0D;
}