.integration_main_div {
  background: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

.setting_integrations_cards_cont {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 10px;

  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 10px;
}

.integrations_card_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.integration_card_bottom {
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  padding: 20px;
  align-content: space-between !important;
  justify-items: center;
  display: flex;
}

.integration_card_bottom > div:nth-child(2) {
  padding-top: 5px;
}

.small_integration_img_cont {
  padding: 0px;
  width: 60px;
  height: 60px;
}

.small_integration_img {
  width: 100%;
  height: 100%;
  margin-top: -13px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .setting_integrations_cards_cont {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 10px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .setting_integrations_cards_cont {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1500px) {
  .setting_integrations_cards_cont {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 10px;
  }
}
