.single-image-feature {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.sidebar-shadow {
  border: 1px solid rgba(6, 18, 36, 0.1);
  padding: 29px 33px;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 9px 26px 0px rgb(31 31 51 / 6%);
  box-shadow: 0px 9px 26px 0px rgb(31 31 51 / 6%);
  margin-bottom: 40px;
}

.sidebar-shadow .sidebar-heading {
  display: inline-block;
  width: 100%;
}

.text-description {
  font-size: 16px;
  color: #37404e;
  line-height: 24px;
  display: inline-block;
  width: 100%;
}

.sidebar-list-job {
  border-top: 1px solid rgba(6, 18, 36, 0.1);
  display: inline-block;
  width: 100%;
  padding: 30px 0px 0px 0px;
  margin: 30px 0px 0px 0px;
}

.sidebar-team-member {
  border-top: 1px solid rgba(6, 18, 36, 0.1);
  display: inline-block;
  width: 100%;
  padding: 30px 0px 0px 0px;
  margin: 30px 0px 0px 0px;
}

.pt-40 {
  padding-top: 40px !important;
}

.small-heading {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.twm-job-hilites {
  padding-bottom: 10px;
  font-weight: 500;
}

.twm-job-hilites li {
  display: block;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 8px 8px 0px;
  position: relative;
  color: #17171d;
  font-size: 16px;
  margin-bottom: 10px;
}

.modal-custom-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgb(56 152 226 / 30%);
  padding: 30px;
  /* margin-top: 90px; */
}

.twm-title {
  font-size: 14px;
  font-weight: 500;
  color: #17171d;
}

.twm-s-info-discription {
  font-size: 14px;
  color: #17171d;
  font-weight: 600;
}

.skills {
  background-color: #1967d236;
  color: #1967d2;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 15px;
}

.skills:hover {
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 15px;
}

.user-profile {
  height: 30px;
  width: 30px;
  border-radius: 20px;
}
