.col_btn {
  display: flex;
  align-items: center;
  gap: 3px;
  /* gap:10px; */
}

.green {
  color: green;
}
