.talent__box {
  width: 100%;
  height: 150px;
  box-shadow: var(--global-shadow);
  background-color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: var(--global-radius);
  transition: 0.4s ease-in-out;
}

.talent__box:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.talent_inner_border {
  border-bottom: 1px solid #acacac;
  margin-bottom: 10px;
}

.talent_inner_border > h4:hover {
  color: var(--background-color);
}

.talent_filter {
  overflow-x: hidden;
  /* max-width: 90vh; */
  /* overflow: scroll; */
}

.talent_header {
  background-color: white;
  box-shadow: var(--global-shadow);
  border-radius: var(--global-radius);
  height: 80px;
  align-items: center;
}

.talent_table {
  border-radius: var(--global-radius) !important;
  margin-top: 10px !important;
}

.outlined_btn {
  background-color: var(--global-border);
  display: grid;
  cursor: pointer;
  place-items: center;
  height: 30px;
  width: 100% !important;
  border-radius: var(--global-radius);
}

.nav_icon {
  display: flex;
  gap: 10px;
}

.talent_filter_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #a29e9e;
}

.talent_filter_info {
  transform: scale(1.4);
  display: flex;
  gap: 10px;
  align-items: center;
}

.talent_info > .row {
  padding-bottom: 10px !important;
}

.inpt__filter > input {
  margin-right: 10px !important;
}

.talent_info {
  max-height: 80vh;
  overflow-x: hidden;
  padding-bottom: 50px;
  overflow: scroll;
}
